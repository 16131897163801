/* Felhasználói szintek:
0 Szuperadmin
- mindent csinálhat

3 Vezető (hibajegyek kiosztása engedélyezett)
- usereket nem módosít, törölni hibajegyet és kommentet nem tud
- külsős szolgáltatókat és eszközöket kezeli
- tervezett karbantartásokat kezeli

5 Adhat hozzá új hibát
- nem tud kiosztani feladatot és határidőt beállítani

10 Karbantartó(csak hibajegyek látszanak)
- csak láthat a hibajegyeket, szerkeszteni is tudja őket
 */



import React from "react";
import { useLocation } from "react-router-dom";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { langs } from "./Common";

import { collection, onSnapshot, query, doc, updateDoc, getDoc } from "firebase/firestore";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function EditUser(props) {
  let location = useLocation();
  const uId = location.state ? location.state.uId : "PeGoNhnpgv8966n2Lu8y";
  const [user, setUser] = useState();
  const [locations, setLocations] = useState([]);

  function userData() {
    const docRef = doc(db, "users", uId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setUser(doc.data());
    });
    return unsubscribe;
    }
    
  useEffect(() => userData(), []);

  function locationsData() {
    const q = query(collection(db, "locations"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setLocations([...newData]);
    });
    return unsubscribe;
  }
    useEffect(() => locationsData(), []);
    
    function handleChangeField(event) {
        var newValue = event.target.value;
      var targetId = event.target.id;
        if (targetId === "name") {
          setUser({ ...user, name: newValue } )

      }
      
      if (targetId === "userLevel") {
        setUser({...user, level: parseInt(newValue,10)})
      }
    }


  function handleUpdateUser(event) {
    event.preventDefault();
    const docRef = doc(db, "users", uId);
    updateDoc(docRef, {
      ...user
    })
      .then((docRef) => {
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChangeDepartmentList(event) {
    var tempActId = event.target.value;
    var tempArray = [];
    if (user) {
      if (!user.hasOwnProperty("locations")) user.locations = [];
      tempArray = user.locations;
    }
    if (tempArray.indexOf(tempActId) === -1 && event.target.checked) {
      tempArray.push(tempActId);
    } else {
      tempArray = tempArray.filter((e) => e !== tempActId);
    }
    setUser({ ...user, locations: tempArray });
  }

  function handleChangeDefaults(event) {
    var tempValue = event.target.value;
    var tempId = event.target.id;
    var tempArray = [];
    if (user) {
      if (!user.hasOwnProperty("defaults")) user.defaults = {};
      if (tempId === "visibleLocationsInTicketList") {
        if (!user.defaults.hasOwnProperty("visibleLocationsInTicketList")) user.defaults.visibleLocationsInTicketList = [];
        tempArray = user.defaults.visibleLocationsInTicketList;
        if (tempArray.indexOf(tempValue) === -1 && event.target.checked) {
          tempArray.push(tempValue);
        } else {
          tempArray = tempArray.filter((e) => e !== tempValue);
        }
        setUser({ ...user, defaults: { ...user.defaults, visibleLocationsInTicketList: tempArray } });
      }

      if (tempId === "defaultLocation") {
        console.log("tempValue", tempValue)
        setUser({ ...user, defaults: { ...user.defaults, defaultLocation: tempValue } });
      }
    }
  }

  return (
    <div className="EditUser">
      {user && (
        <>
          <Container>
            <Form>
              <Form.Group as={Row} className="pb-4 pt-4">
                <Form.Label column sm={2}>
                  Felhasználó neve
                </Form.Label>
                <Col sm={4}>
                      <Form.Control
                        id="name"
                        value={user.hasOwnProperty("name") ? user.name : "" }
                        onChange={handleChangeField}
                      />
                </Col>
                <Col sm={4}>
                  <Button id="saveButton" onClick={handleUpdateUser} variant="primary" type="submit">
                    Mentés
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="pb-4 pt-4">
                 <Form.Label column sm={2}>
                  Felhasználói szint
                </Form.Label>
                <Col className="text-start" sm={4}>
                  <Form.Select id="userLevel" aria-label="userLevel" onChange={handleChangeField} value={user.hasOwnProperty("level") ? user?.level : "label"}>
                        <option key="default" value="label" disabled>
                          Válassz...
                    </option>
                    <option value={0}>Szuperadmin</option>
                    <option value={3}>Vezető (hibajegyek kiosztása engedélyezett)</option>
                    <option value={5}>Adhat hozzá új hibát</option>
                    <option value={10}>Karbantartó (csak saját hibajegyek látszanak)</option>
                      </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="pb-4 pt-4">
                 <Form.Label column sm={2}>
                  A felhasználóhoz tartozó részlegek
                </Form.Label>
                <Col className="text-start">
                  {locations.map((d, i) => (
                    <>
                      <Form.Check
                        key={i}
                        type="checkbox"
                        id="listlocations"
                        label={d.hasOwnProperty("name") ? d.name : ""}
                        onChange={handleChangeDepartmentList}
                        checked={user.hasOwnProperty("locations") && user.locations.indexOf(d.id) !== -1}
                        value={d.id}
                      />
                    </>
                  ))}
                </Col>
              </Form.Group>
              <Row className="border-secondary border-bottom pb-2"><Col className="text-start">
                Alapbeállítások
              </Col></Row>
              <Form.Group as={Row} className="pb-4 pt-4">
                 <Form.Label column sm={2}>
                  Ajánlatok listájában látható részlegek
                </Form.Label>
                <Col className="text-start">
                  {locations.map((d, i) => (
                    //csak a felhasználóhoz tartozó részlegeket listázza ki
                    user.locations?.find(x => x === d.id) && <>
                      {console.log("userdefaults", user.defaults?.visibleLocationsInTicketList?.indexOf(d.id))}
                      <Form.Check
                        key={i}
                        type="checkbox"
                        id="visibleLocationsInTicketList"
                        label={d.hasOwnProperty("name") ? d.name : ""}
                        onChange={handleChangeDefaults}
                        checked={user.hasOwnProperty("defaults") && user.defaults.hasOwnProperty("visibleLocationsInTicketList") && user.defaults?.visibleLocationsInTicketList?.indexOf(d.id) !== -1}
                        value={d.id}
                      />
                    </>
                  ))}
                </Col>
              </Form.Group>
               <Form.Group as={Row} className="pb-4 pt-4">
                 <Form.Label column sm={2}>
                  Új ajánlatnál beállított részleg
                </Form.Label>
                <Col className="text-start" sm={4}>
                  <Form.Select id="defaultLocation" aria-label="Location" onChange={handleChangeDefaults} value={user.defaults?.hasOwnProperty("defaultLocation") ? user.defaults.defaultLocation : "label"}>
                        <option key="default" value="label" disabled>
                          Válassz...
                        </option>
                        {locations.map((d) => (
                          <option key={d.id} value={d.id}>
                            {d.name}
                          </option>
                        ))}
                      </Form.Select>
                </Col>
              </Form.Group>
            </Form>
          </Container>
        </>
      )}
    </div>
  );
}

export default EditUser;
