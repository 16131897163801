/* planned types: 
0 - maint_inhouse
1 - external */

import {
    collection, onSnapshot,
    query
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    ListGroup,
    Offcanvas,
    Row,
} from "react-bootstrap";
import { langs } from "./Common";
import { db } from "./firebase";

function Planned(props) {
  const userDetails = props.userDetails;
  const [assets, setAssets] = useState();
  const [locations, setLocations] = useState([]);
  const [lang, setLang] = useState(langs[0].id);
  const [locationsVisible, setLocationsVisible] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const handleCloseDrawer = () => setShowDrawer(false);
  const handleShowDrawer = () => setShowDrawer(true);

  /* planned types: 
0 - inhouse
1 - external */
  const [planned, setPlanned] = useState([]);

  function copyDefaults() {
    if (userDetails?.defaults?.hasOwnProperty("visibleLocationsInTicketList"))
      setLocationsVisible(userDetails.defaults.visibleLocationsInTicketList);
  }

  useEffect(() => copyDefaults(), [userDetails]);

  function assetsData() {
    const q = query(collection(db, "assets"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setAssets([...newData].sort((a, b) => b.dateAdded - a.dateAdded));
    });
    return unsubscribe;
  }
  useEffect(() => assetsData(), []);

  function plannedData() {
    const q = query(collection(db, "planned"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setPlanned([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => plannedData(), []);

  function locationsData() {
    const q = query(collection(db, "locations"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setLocations([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => locationsData(), []);

  function handleChangeLocationsVisible(event, dId) {
    var tempActId = dId;
    var tempArray = [...locationsVisible];
    if (tempArray.indexOf(tempActId) === -1 /* && event.target.checked */) {
      tempArray.push(tempActId);
    } else {
      tempArray = tempArray.filter((e) => e !== tempActId);
    }
    setLocationsVisible([...tempArray]);
  }

  /*  let tempArray = Array.from(event.target.selectedOptions, option => option.value); */

  function handleReOrder(event, param) {
    /* if (param === "title") {
      // ha név alapján kell szortírozni, akkor ez fut le. az arraysSame-mel leellenőrzi, hogy már szortírozva van-e
      // (tehát hogy az array megegyezik-e egy szortírozott array-jel, és ha igen, akkor fordítva szortírozza)
      if (arraysSame([...assetsView],[...assetsView].sort((a, b) => clients?.find((c) => c.id === a.clientId)?.name.localeCompare(clients?.find((c) => c.id === b.clientId)?.name))))
      {  setAssetsView([...assetsView].sort((a, b) => clients?.find((c) => c.id === b.clientId)?.name.localeCompare(clients?.find((c) => c.id === a.clientId)?.name)));}
      else {
        setAssetsView([...assetsView].sort((a, b) => clients?.find((c) => c.id === a.clientId)?.name.localeCompare(clients?.find((c) => c.id === b.clientId)?.name)));
      }
    }
    if (param === "dateAdded") {
       if (arraysSame([...assetsView],[...assetsView].sort((a, b) => b.dateAdded - a.dateAdded)))
      {  setAssetsView([...assetsView].sort((a, b) => a.dateAdded - b.dateAdded));}
      else {  setAssetsView([...assetsView].sort((a, b) => b.dateAdded - a.dateAdded));}

    } */
  }

  /*     function changeFolder() {
        if (assets && folder) {
            var tempArray = [...assets];
            if (folder === 1) {
                tempArray = tempArray.filter(x => ((!x.hasOwnProperty("billed") || x.billed === false) & x.statusId != 3));
                setAssetsView([...tempArray]);
            }
            if (folder === 2) {
                tempArray = tempArray.filter(x => (x.billed === true || x.statusId === 3));
                if (onlyBilled === true) {
                    tempArray = tempArray.filter(x => (x.billed === true));
                }
                if (onlyLost === true) {
                    tempArray = tempArray.filter(x => (x.statusId === 3));
                }
                setAssetsView([...tempArray]);
            }
        }
    }

  useEffect(() => changeFolder(), [folder, onlyBilled, onlyLost]) */

  function timestampToDate(ts) {
    ts = new Date(ts * 1000);
    return (
      ts.toLocaleString("en-EN", { year: "numeric" }) +
      "-" +
      ts.toLocaleString("en-EN", { month: "2-digit" }) +
      "-" +
      ts.toLocaleString("en-EN", { day: "2-digit" })
    );
  }

  return (
    <div>
      <Container className="m-2">
        <Row className="pt-4 pb-4">
          <Col>
            <Button variant="primary" onClick={handleShowDrawer}>
              Beállítások
            </Button>
          </Col>

          <Offcanvas show={showDrawer} onHide={handleCloseDrawer}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Beállítások</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {locations && userDetails && (
                <ListGroup>
                  {locations.map(
                    (d) =>
                      userDetails.locations?.find((x) => x === d.id) && (
                        <>
                          {" "}
                          <ListGroup.Item
                            action
                            value={d.id}
                            active={
                              locationsVisible &&
                              locationsVisible.indexOf(d.id) !== -1
                            }
                            onClick={(e) =>
                              handleChangeLocationsVisible(e, d.id)
                            }
                          >
                            {d.hasOwnProperty("name") ? d.name : ""}
                          </ListGroup.Item>{" "}
                        </>
                      )
                  )}
                </ListGroup>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </Row>
      </Container>
      <Container>
        <table className="ticketsTable">
          <thead>
            <tr>
              <th
                colspan={2}
                onClick={(event) => handleReOrder(event, "title")}
              >
                Név
              </th>
              {/*    <th>Státusz</th> */}
              <th colspan={2}>Típus</th>
              <th>Helyszín</th>
              <th>Részleg</th>
              <th>Kezdés dátuma</th>
              <th>Vég dátuma</th>
              <th>Gyakoriság</th>
              {/*  {(userDetails?.hasOwnProperty("level") && userDetails?.level === 0) && <th>Törlés</th>} */}
            </tr>
          </thead>
          <tbody>
            {userDetails &&
              assets &&
              planned &&
              planned?.map((p, i) => {
                var pA = assets?.find((a) => a.id === p.asset);
                if (
                  pA &&
                  pA?.hasOwnProperty("location") &&
                  locationsVisible.indexOf(pA.location) !== -1
                )
                  return (
                    <tr key={i}>
                      <td colspan={2} data-label="Név">
                        {pA.name}
                      </td>
                      <td colspan={2} data-label="Típus">
                        {pA.type}
                      </td>
                      <td data-label="Helyszín" id="location">
                        {locations.find((l) => l.id === pA.location)?.name}
                      </td>
                      <td data-label="Részleg" id="sublocation">
                        {locations
                          .find((l) => l.id === pA.location)
                          ?.hasOwnProperty("sublocations") &&
                          locations
                            .find((l) => l.id === pA.location)
                            ?.sublocations?.find((s) => s.id === pA.sublocation)
                            ?.name}
                      </td>
                      <td data-label="Kezdés dátuma">
                        {p.hasOwnProperty("dtstart")
                          ? timestampToDate(p.dtstart)
                          : ""}
                      </td>
                      <td data-label="Vég dátuma">
                        {p.hasOwnProperty("until")
                          ? p.until.substring(0, 4) +
                            "-" +
                            p.until.substring(4, 6) +
                            "-" +
                            p.until.substring(6, 8)
                          : ""}
                      </td>
                      <td data-label="Gyakoriság">
                        {p.hasOwnProperty("freq") ? p.freq : ""}
                      </td>
                      {/*   {(userDetails?.hasOwnProperty("level") && userDetails.level === 0) &&
                                                <td data-label="Törlés">
                                                    <XSquareFill
                                                        color="#0d6efd"
                                                        style={{cursor: "pointer"}}
                                                        onClick={(event) => handleDeleteAsset(event, p.id)} />
                                                </td>} */}
                    </tr>
                  );
              })}
          </tbody>
        </table>
      </Container>
    </div>
  );
}

export default Planned;
