import { db } from "./firebase";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, onSnapshot, query, addDoc, doc, deleteDoc } from "firebase/firestore";
import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import HVToast from "./HVToast";
import EditLocation from "./EditLocation";

function Locations() {
  /*   const [varGroups, setVarGroups] = useState([]); */
  const [name, setName] = useState("");
  const [locations, setLocations] = useState([]);

  function locationsData() {
    const q = query(collection(db, "locations"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setLocations([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => locationsData(), []);

  function handleAddLocation(event) {
    event.preventDefault();
    const dbRef = collection(db, "locations");
    addDoc(dbRef, {
      name: name
    })
      .then((docRef) => {
        console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
      })
      .catch((error) => {
        console.log(error);
      });
    setName("");
  }

  function handleDeleteLocation(event, dId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd a helyszínt?")) {
      const docRef = doc(db, "locations", dId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div className="Locations">
      <Container className="pt-4">
        <Form>
          <Row>
            <Col className="text-start align-middle">
              <Form.Label htmlFor="inputTitle">Új helyszín létrehozása</Form.Label>
            </Col>
          </Row>
          <Row className="pt-2 pb-2">
            <Col className="text-start align-middle">
              <Form.Control id="activity" onChange={(e) => setName(e.target.value)} />
            </Col>
            <Col>
              <Button onClick={handleAddLocation} variant="primary" type="submit">
                Hozzáad
              </Button>
            </Col>
          </Row>
        </Form>
        {locations &&
          locations.map((d, i) => (
            <Row key={i} className="pt-2 pb-2 border-secondary border-bottom">
              <Col className="d-flex justify-content-start align-items-center">
                {d.hasOwnProperty("name") && d.name}
              </Col>
              <Col>
                <Button onClick={(event) => handleDeleteLocation(event, d.id)} variant="primary" type="submit">
                  Törlés
                </Button>
              </Col>
            </Row>
          ))}
      </Container>
    </div>
  );
}

export default Locations;
