import { db } from "./firebase";
import { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  query,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import React from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  ListGroup,
  Offcanvas,
  Modal,
} from "react-bootstrap";
import { XSquareFill, FileEarmarkPlus } from "react-bootstrap-icons";
import { langs } from "./Common";
import EditTicket from "./EditTicket";
import HVToast from "./HVToast";
import { statusSetStyle } from "./statusSetStyle";

function Tickets(props) {
  const userDetails = props.userDetails;
  const users = props.users;
  const [tickets, setTickets] = useState();
  const [ticketsView, setTicketsView] = useState();
  const [statuses, setStatuses] = useState();
  const [locations, setLocations] = useState([]);
  const [externals, setExternals] = useState();

  const [lang, setLang] = useState(langs[0].id);
  const [onlyNotFinished, setOnlyNotFinished] = useState(false);
  const [onlyLost, setOnlyLost] = useState(false);
  const [onlyBilled, setOnlyBilled] = useState(false);
  const [locationsVisible, setLocationsVisible] = useState([]);
  const [assetsArray, setAssetsArray] = useState([]);
  const [folder, setFolder] = useState(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [currentTicket, setCurrentTicket] = useState("");
  const [planned, setPlanned] = useState();
  const handleCloseDrawer = () => setShowDrawer(false);
  const handleShowDrawer = () => setShowDrawer(true);

  const [showTicket, setShowTicket] = useState(false);
  const handleCloseTicket = () => {
    setCurrentTicket("");
    setShowTicket(false);
  };
  const handleShowTicket = (event, tId, plannedId) => {
    setCurrentTicket({
      tId: tId,
      plannedId: plannedId,
    });
    setShowTicket(true);
  };

  const [showToast, setShowToast] = useState(false);

  function copyDefaults() {
    if (userDetails?.defaults?.hasOwnProperty("visibleLocationsInTicketList"))
      setLocationsVisible(userDetails.defaults.visibleLocationsInTicketList);
  }

  useEffect(() => copyDefaults(), [userDetails]);

  useEffect(() => tickets && setTicketsView([...tickets]), [tickets]);

  /* function assetsData() {
        const q = query(collection(db, "assets"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setAssets([...newData]);
        });
        return unsubscribe;
    }
    useEffect(() => assetsData(), []); */

  function plannedData() {
    const q = query(collection(db, "planned"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setPlanned([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => plannedData(), []);

  function externalsData() {
    const q = query(collection(db, "externals"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setExternals([...newData].sort((a, b) => a.name.localeCompare(b.name)));
    });
    return unsubscribe;
  }
  useEffect(() => externalsData(), []);

  function ticketsData() {
    const q = query(collection(db, "tickets"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          // ha ez egy olyan ticket, amit tervezett karbantartásból készítettek (azaz van plannedId-je),
          // akkor nincs a ticket rekordban location, tehát bele kell tenni az asset location-jét
          ...(doc.data().hasOwnProperty("plannedId") && {
            location: assetsArray?.find(
              (a) =>
                a.id ===
                planned?.find((p) => p.id === doc.data().plannedId)?.asset
            )?.location,
          }),
          ...doc.data(),
        });
      });
      setTickets([...newData].sort((a, b) => b.dateAdded - a.dateAdded));
      /* setFolder(1); */
    });
    return unsubscribe;
  }
  useEffect(() => ticketsData(), [planned, assetsArray]);

  function locationsData() {
    const q = query(collection(db, "locations"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      console.log("querySnapshotLoc", querySnapshot);
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setLocations([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => locationsData(), []);

  function statusData() {
    const q = query(doc(db, "other", "statuses"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      console.log("querySnapshot", querySnapshot.data());
      const newData = querySnapshot.data() ? Object.values(querySnapshot.data()) : [];
  
      console.log("newData", newData);
      setStatuses([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => statusData(), []);

  
 /*  useEffect(() => {
    async function statusData() {
      const docRef = doc(db, "other", "statuses");
      console.log("statusesinData", statuses);
      const docSnap = await getDoc(docRef); */
     /*  getDoc(docRef).then((doc) => {
        var tempArray = [...Object.values(doc.data())];
        setStatuses([...tempArray]);
      }); */
     /*  if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        var tempArray = [...Object.values(docSnap.data())];
        setStatuses([...tempArray]);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
      
    }
    
    statusData()}, []); */

  /*     const getItems = async () => {
  // fetching all documents by mapping an array of promises and using Promise.all()
    const itemsDocs = await Promise.all(planned?.map(p => getDoc(doc(db, 'assets', p.asset))))
  // mapping array of document data
  /* const items = itemsDocs.map(i => i.data()); */
  /*   const items = [];
  itemsDocs.map((i) => {
    items.push({...i.data(), id: i.id})
  });
        
        setAssetsArray([...items]);
        return (items);
    } */

  useEffect(() => {
    async function getItems() {
      // fetching all documents by mapping an array of promises and using Promise.all()
      const itemsDocs = await Promise.all(
        planned?.map((p) => getDoc(doc(db, "assets", p.asset)))
      );
      // mapping array of document data
      /* const items = itemsDocs.map(i => i.data()); */
      const items = [];
      itemsDocs.map((i) => {
        items.push({ ...i.data(), id: i.id });
      });
      setAssetsArray([...items]);
      return items;
    }

    planned && getItems();
  }, [planned]);

  function handleDeleteTicket(event, ticketId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd ezt a hibajegyet?")) {
      const docRef = doc(db, "tickets", ticketId);
      deleteDoc(docRef)
        .then(() => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleChangeLocationsVisible(event, dId) {
    var tempActId = dId;
    var tempArray = [...locationsVisible];
    if (tempArray.indexOf(tempActId) === -1 /* && event.target.checked */) {
      tempArray.push(tempActId);
    } else {
      tempArray = tempArray.filter((e) => e !== tempActId);
    }
    setLocationsVisible([...tempArray]);
  }

  /*  let tempArray = Array.from(event.target.selectedOptions, option => option.value); */

  function handleReOrder(event, param) {
    /* if (param === "title") {
      // ha név alapján kell szortírozni, akkor ez fut le. az arraysSame-mel leellenőrzi, hogy már szortírozva van-e
      // (tehát hogy az array megegyezik-e egy szortírozott array-jel, és ha igen, akkor fordítva szortírozza)
      if (arraysSame([...ticketsView],[...ticketsView].sort((a, b) => clients?.find((c) => c.id === a.clientId)?.name.localeCompare(clients?.find((c) => c.id === b.clientId)?.name))))
      {  setTicketsView([...ticketsView].sort((a, b) => clients?.find((c) => c.id === b.clientId)?.name.localeCompare(clients?.find((c) => c.id === a.clientId)?.name)));}
      else {
        setTicketsView([...ticketsView].sort((a, b) => clients?.find((c) => c.id === a.clientId)?.name.localeCompare(clients?.find((c) => c.id === b.clientId)?.name)));
      }
    }
    if (param === "dateAdded") {
       if (arraysSame([...ticketsView],[...ticketsView].sort((a, b) => b.dateAdded - a.dateAdded)))
      {  setTicketsView([...ticketsView].sort((a, b) => a.dateAdded - b.dateAdded));}
      else {  setTicketsView([...ticketsView].sort((a, b) => b.dateAdded - a.dateAdded));}

    } */
  }

  function changeFolder(event, folderId) {
    if (tickets && folder) {
      var tempArray = [...tickets];
      if (folder === 1) {
        tempArray = tempArray.filter(
          (x) => x.hasOwnProperty("statusId") && x.statusId !== 2
        );
        setTicketsView([...tempArray]);
      }
      if (folder === 2) {
        tempArray = tempArray.filter(
          (x) => x.hasOwnProperty("statusId") && x.statusId === 2
        );
        setTicketsView([...tempArray]);
      }
    }
  }

  useEffect(() => changeFolder(), [folder, tickets]);

  return (
    <div>
      <Container className="m-2">
        <Row className="pt-4 pb-4">
          <Col xs={4} md={4}>
            <Form.Select
              aria-label="Mappa"
              value={folder}
              onChange={(event) => setFolder(parseInt(event.target.value, 10))}
            >
              <option value={1}>Aktuális</option>
              <option value={2}>Befejezett</option>
            </Form.Select>
          </Col>

          {userDetails?.hasOwnProperty("level") && userDetails.level <= 5 && (
            <Col>
              {/*  <Link to="/ticket" state={{ tId: "new", users: users, userDetails: userDetails }}> */}
              <Button
                onClick={(event) => handleShowTicket(event, "new")}
                id="addTicket"
                variant="primary"
                type="submit"
              >
                <FileEarmarkPlus /> Új feladat
              </Button>
            </Col>
          )}
          <Col>
            <Button variant="primary" onClick={handleShowDrawer}>
              Beállítások
            </Button>
          </Col>
          <Offcanvas show={showDrawer} onHide={handleCloseDrawer}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Beállítások</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ListGroup className="pb-4">
                {/*  Kimenő üzenetek esetén a szűrőkapcsolók az alábbiak */}
                {folder === 1 && (
                  <>
                    {" "}
                    <ListGroup.Item
                      action
                      id="onlyNotFinished"
                      value={onlyNotFinished}
                      active={onlyNotFinished && onlyNotFinished}
                      onClick={() =>
                        setOnlyNotFinished(onlyNotFinished ? false : true)
                      }
                    >
                      Csak befejezetlen
                    </ListGroup.Item>{" "}
                  </>
                )}
                {/*   Archív üzenetek esetén pedig a szürőkapcsolók az alábbiak */}
                {folder === 2 && (
                  <>
                    {" "}
                    <ListGroup.Item
                      action
                      id="onlyNotFinished"
                      value={onlyNotFinished}
                      active={onlyNotFinished && onlyNotFinished}
                      onClick={() =>
                        setOnlyNotFinished(onlyNotFinished ? false : true)
                      }
                    >
                      Csak befejezetlen
                    </ListGroup.Item>
                    {/*
                  <Form.Check type="checkbox" id="onlyBilled" label="Csak számlázottak" onChange={() => setOnlyBilled(onlyBilled ? false : true)} checked={onlyBilled} />
                  <Form.Check type="checkbox" id="onlyLost" label="Csak elvesztettek" onChange={() => setOnlyLost(onlyLost ? false : true)} checked={onlyLost} /> */}{" "}
                  </>
                )}
              </ListGroup>

              {locations && userDetails && (
                <ListGroup>
                  {locations.map(
                    (d) =>
                      userDetails.locations?.find((x) => x === d.id) && (
                        <>
                          {" "}
                          <ListGroup.Item
                            action
                            value={d.id}
                            active={
                              locationsVisible &&
                              locationsVisible.indexOf(d.id) !== -1
                            }
                            onClick={(e) =>
                              handleChangeLocationsVisible(e, d.id)
                            }
                          >
                            {d.hasOwnProperty("name") ? d.name : ""}
                          </ListGroup.Item>{" "}
                        </>
                      )
                  )}
                </ListGroup>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </Row>
      </Container>
      <Container>
      {!(statuses &&
              userDetails &&
              users &&
              assetsArray &&
              tickets &&
              ticketsView &&
              externals &&
              planned) ? <p>Betöltés...</p> : 
        <table className="ticketsTable">
          <thead>
            <tr>
              <th>No</th>
              <th onClick={(event) => handleReOrder(event, "dateAdded")}>
                Dátum
              </th>
              <th
                colSpan={2}
                onClick={(event) => handleReOrder(event, "title")}
              >
                Cím
              </th>
              <th>Státusz</th>
              <th>Helyszín</th>
              <th>Szoba</th>
              <th>Felelősök</th>
              <th>Határidő</th>
              <th>Létrehozta</th>
              {userDetails?.hasOwnProperty("level") &&
                userDetails?.level === 0 && <th>Törlés</th>}
            </tr>
          </thead>
          <tbody>
            {ticketsView?.map((p, i) => {
                if (
                  p.hasOwnProperty("location") &&
                  locationsVisible.indexOf(p.location) !== -1
                )
                  return (
                    <tr key={i}>
                      <td data-label="No">
                        {p.hasOwnProperty("serialNo")
                          ? p.serialNo.toString().padStart(6, "0")
                          : " "}
                      </td>
                      <td data-label="Dátum">
                        {new Date(
                          p.dateAdded.seconds * 1000
                        ).toLocaleDateString("hu-HU")}
                      </td>
                      <td data-label="Cím" colSpan={2}>
                        <b>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(event) =>
                              handleShowTicket(
                                event,
                                p.id,
                                p.hasOwnProperty("plannedId")
                                  ? p.plannedId
                                  : undefined
                              )
                            }
                          >
                            {p.title ? p.title : ""}
                          </div>
                        </b>
                        {/* <Link
                                                    to="/ticket"
                                                    state={{tId: p.id, users: users, userDetails: userDetails}}
                                                    style={{
                                                    textDecoration: "none",
                                                    color: "#000"
                                                }}><b>{p.title
                                                        ? p.title
                                                        : ""}
                                                </b></Link> */}
                      </td>
                      <td data-label="" className="small">
                        <div className={statusSetStyle(p.statusId)}>
                          {p.hasOwnProperty("statusId") &&
                          statuses.find((s) => s.sId === p.statusId) &&
                          statuses
                            .find((s) => s.sId === p.statusId)
                            .hasOwnProperty("names") &&
                          statuses
                            .find((s) => s.sId === p.statusId)
                            .names.find((l) => l.id === lang) &&
                          statuses
                            .find((s) => s.sId === p.statusId)
                            .names.find((l) => l.id === lang)
                            .hasOwnProperty("name")
                            ? statuses
                                .find((s) => s.sId === p.statusId)
                                .names.find((l) => l.id === lang).name
                            : ""}
                        </div>
                      </td>
                      <td data-label="Helyszín">
                        {p.hasOwnProperty("location") &&
                          locations.find((l) => l.id === p.location)?.name}
                      </td>
                      <td data-label="Szoba">
                        {p.hasOwnProperty("roomNo") &&
                        locations
                          .find((l) => l.id === p.location)
                          ?.hasOwnProperty("rooms") === true ? (
                          p.roomNo
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </td>
                      <td data-label="Felelősök">
                        {(!p.hasOwnProperty("delegatedTo") ||
                          p.delegatedTo.length === 0) &&
                          (!p.hasOwnProperty("delegatedExternalsTo") ||
                            p.delegatedExternalsTo.length === 0) && (
                            <span>&nbsp;</span>
                          )}
                        {p.hasOwnProperty("delegatedTo") &&
                          p.delegatedTo.length !== 0 &&
                          p.delegatedTo.map((k) => (
                            <div>{users?.find((u) => u.id === k)?.name}</div>
                          ))}
                        {p.hasOwnProperty("delegatedExternalsTo") &&
                          p.delegatedExternalsTo.length !== 0 &&
                          p.delegatedExternalsTo.map((k) => (
                            <div>
                              {externals?.find((u) => u.id === k)?.name}
                            </div>
                          ))}
                      </td>
                      <td data-label="Határidő">
                        {p.hasOwnProperty("deadline") &&
                        p.hasOwnProperty("hasDeadline")
                          ? new Date(p.deadline * 1000).toLocaleDateString(
                              "hu-HU"
                            )
                          : "nincs"}
                      </td>
                      <td data-label="Létrehozta">
                        {p.hasOwnProperty("createdBy")
                          ? users?.find((x) => x.id === p.createdBy)?.name
                          : ""}
                      </td>
                      {userDetails?.hasOwnProperty("level") &&
                        userDetails.level === 0 && (
                          <td data-label="Törlés">
                            <XSquareFill
                              color="#0d6efd"
                              onClick={(event) =>
                                handleDeleteTicket(event, p.id)
                              }
                            />
                          </td>
                        )}
                    </tr>
                  );
              })}
          </tbody>
        </table>}
      </Container>

      <Modal
        show={showTicket}
        onHide={handleCloseTicket}
        className={"modal-xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Feladat szerkesztése</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditTicket
            users={users}
            userDetails={userDetails}
            tId={currentTicket?.tId}
            planned={planned}
            plannedId={currentTicket?.plannedId}
            assetsArray={assetsArray}
            showToast={showToast}
            setShowToast={setShowToast}
            handleCloseTicket={handleCloseTicket}
            tickets={tickets}
            externals={externals}
            statuses={statuses}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseTicket}>
            Bezárás
          </Button>
        </Modal.Footer>
      </Modal>

      <HVToast txt="Mentve" showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
}

export default Tickets;
