import React, {useState} from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { collection, addDoc, Timestamp } from "@firebase/firestore";
import { db } from "./firebase";

function EditAsset(props) {
    const users = props.users;
    const userDetails = props.userDetails;
    const setShowToast = props.setShowToast;
    const locations = props.locations;

    var defaultLocation = userDetails?.defaults?.hasOwnProperty("defaultLocation") ? userDetails.defaults?.defaultLocation : "";

    const newAssetData = {
        name: "",
        type: "",
        createdBy: users?.find((x) => x?.uId === userDetails?.uId).id,
        dateAdded: Timestamp.fromDate(new Date()),
        location: defaultLocation,
        sublocation: null,
        maint_external: 0,
        maint_inhouse: 0,
        notes: "",
        spares: 0,
        status: 0
    }

    const [asset, setAsset] = useState(newAssetData);


    function handleUpdateAsset(event) {
        event.preventDefault();
                  const dbRef = collection(db, "assets");
       
                        addDoc(dbRef, asset)
                          .then((docRef) => {
                            setShowToast(true);
                            console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
                        })
                        .catch((error) => {
                            console.log(error);
                        });
        
       
      }

    return(
        locations && <div className="EditAsset">
        {console.log("locations", locations)}
        <Container>
        <Form.Group as={Row} className="pb-4 pt-4">
                  <Form.Label column sm={2}>
                    Eszköz neve
                  </Form.Label>
                  <Col sm={4} className="pb-2">
                  <Form.Control
                        value={asset.name}
                        onChange={(event) => setAsset({ ...asset, name: event.target.value })}
                      />   
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="pb-4 pt-4">
                  <Form.Label column sm={2}>
                    Eszköz típusa
                  </Form.Label>
                  <Col sm={4} className="pb-2">
                  <Form.Control
                        value={asset.type}
                        onChange={(event) => setAsset({ ...asset, type: event.target.value })}
                      />   
                  </Col>
                </Form.Group>
                 {/*    csak akkor legyen választható, ha az adott userhez több helyszín is tartozhat */}
                 {userDetails?.locations?.length > 1 &&
                <Form.Group as={Row} className="pb-4 pt-4">
                                  <Form.Label column sm={2}>
                                      Helyszín
                                  </Form.Label>
                                  <Col sm={4}>
                    <Form.Select
                      id="locationId"
                      aria-label="Location"
                      onChange={(event) => setAsset({ ...asset, location: event.target.value })}
                      value={asset.hasOwnProperty("location") ? asset.location : "label"}>
                                  
                                          <option key="default" value="label" disabled>
                                              Válassz...
                                          </option>
                                          {userDetails?.locations?.map((l) => (
                                              <option key={l} value={l}>
                                                  {locations.find((k) => k.id === l)?.name}
                                              </option>
                                          ))}
                                      </Form.Select>
                  </Col>
                </Form.Group>
                 
                          }
                <Row className="pt-4 pb-4">
                  <Col sm={2} xs={12}></Col>
                  <Col sm={4} xs={12}>
                    <Button
                    id="saveButton"
                    disabled={asset?.name == ""}
                      onClick={handleUpdateAsset}
                      variant="primary"
                      type="submit"
                    >
                      Eszköz mentése
                  </Button>
                  </Col>
                </Row>
        </Container>
        </div>

    )
}

export default EditAsset;