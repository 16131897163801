import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import rrulePlugin from "@fullcalendar/rrule";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import interactionPlugin from "@fullcalendar/interaction";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css"; // needs additional webpack config!
import huLocale from "@fullcalendar/core/locales/hu";
import { useState, useEffect } from "react";
import { db } from "./firebase";
import EditTicket from "./EditTicket";
import { Button, Card, Modal } from "react-bootstrap";
import { collection, onSnapshot, query, doc, getDoc } from "firebase/firestore";

import { PlusCircle } from "react-bootstrap-icons";
import { statusSetStyle } from "./statusSetStyle";
import { langs } from "./Common";

export function MainCalendar(props) {
  const [lang, setLang] = useState(langs[0].id);
  const userDetails = props.userDetails;
  const users = props.users;
  const [tickets, setTickets] = useState();
  const [statuses, setStatuses] = useState();
  const [ticketDeadlines, setTicketDeadlines] = useState([]);
  const [planned, setPlanned] = useState();
  const [locations, setLocations] = useState([]);
  const [externals, setExternals] = useState();
  const [assetsArray, setAssetsArray] = useState([]);

  const [currentTicket, setCurrentTicket] = useState();
  const [showTicket, setShowTicket] = useState(false);
  const handleCloseTicket = () => {
    setCurrentTicket({});
    setShowTicket(false);
  };
  const handleShowTicket = (event, tId, plannedId, dateClicked) => {
    setCurrentTicket({
      tId: tId,
      plannedId: plannedId,
      dateClicked: dateClicked
    });
    setShowTicket(true);
  };

  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  function handleResize() {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    calendarRef?.current
      ?.getApi()
      .changeView(isMobile ? "dayGridDay" : "dayGridWeek");
  }, [isMobile])

  function ticketsData() {
    const q = query(collection(db, "tickets"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setTickets([...newData].sort((a, b) => b.dateAdded - a.dateAdded));
    });
    return unsubscribe;
  }
  useEffect(() => ticketsData(), []);

  function plannedData() {
    const q = query(collection(db, "planned"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setPlanned([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => plannedData(), []);

  function locationsData() {
    const q = query(collection(db, "locations"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setLocations([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => locationsData(), []);

      function statusData() {
        const docRef = doc(db, "other", "statuses");
        getDoc(docRef).then((doc) => {
          setStatuses(Object.values(doc.data()));
        });
      }
  useEffect(() => statusData(), [tickets]);
  
  function externalsData() {
    const q = query(collection(db, "externals"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setExternals([...newData].sort((a, b) => a.name.localeCompare(b.name)));
    });
    return unsubscribe;
  }
  useEffect(() => externalsData(), []);

  /* useEffect(() => {
  const getItems = async () => {
    // fetching all documents by mapping an array of promises and using Promise.all()
    const itemsDocs = await Promise.all(planned?.map(p => getDoc(doc(db, 'assets', p.asset))))
    // mapping array of document data
    const items = itemsDocs.map(i => i.data())
    console.log("items", items);
  }
  planned && getItems();
}, [planned]) */

  const getItems = async () => {
    // fetching all documents by mapping an array of promises and using Promise.all()
    const itemsDocs = await Promise.all(
      planned?.map((p) => getDoc(doc(db, "assets", p.asset)))
    );
    // mapping array of document data
    /* const items = itemsDocs.map(i => i.data()); */
    const items = [];
    itemsDocs.map((i) => {
      items.push({ ...i.data(), id: i.id });
    });
    return items;
  };

  function createDeadlineArray() {
    var tempArray = [];
    tickets?.map(
      (t) =>
        t.deadline &&
        tempArray.push({
          title: t.title,
          start: t.startDate ? new Date(t.startDate * 1000) : new Date(t.deadline * 1000),
          end: t.startDate? new Date(t.deadline * 1000) : new Date(t.deadline * 1000 + 60 * 60 * 1000),
          location: t.location,
          ticketId: t.id,
          ...(t.hasOwnProperty("plannedId") && { plannedId: t.plannedId }),
          statusId: t.statusId,
          delegatedTo: t.delegatedTo
        })
    );
    console.log("planned", planned);
    getItems()
      .then((plannedItems) => {
        // azoknak a ticketeknek, amik tervezett karbantartásból készültek, nincs location rekordja, ezért
        // itt beletesszük az asset location-jét, ami hozzá tartozik
        tempArray.map((t) => {
          if (t.hasOwnProperty("plannedId")) {
            t.location = plannedItems.find(
              (a) => a.id === planned?.find((p) => p.id === t.plannedId)?.asset
            )?.location;
          }
        });

        planned?.map((p) => {
          // csak akkor rakja bele a naptárba a tervezett karbantartást, ha be van állítva, hogy külsős ÉS a tervezett karbantartás típusa is külsős azaz 1,
          // VAGY ha be van álltva, hogy belsős (az asset-en), ÉS a tervezett karbantartás (planned) típusa is belsős, azaz 0
          if (
            (plannedItems.find((x) => x.id === p.asset)?.maint_external &&
              p.type === 1) ||
            (plannedItems.find((x) => x.id === p.asset)?.maint_inhouse &&
              p.type === 0)
          ) {
            tempArray.push({
              title: plannedItems.find((x) => x.id === p.asset)?.name,
              rrule: {
                dtstart: new Date(p.dtstart * 1000).toISOString(),
                until: p.until,
                freq: p.freq,
              },
              location: plannedItems?.find((x) => x.id === p.asset)?.location,
              planned: true,
              plannedId: p.id,
            });
          }
        });
        setAssetsArray([...plannedItems]);
        setTicketDeadlines([...tempArray]);
      })
      .catch((error) => {
        // Handle/report error
      });
  }

  useEffect(() => createDeadlineArray(), [tickets]);


  function handleAddPlannedTicket(event, pId) {
    console.log("event.target", event.target);
    console.log("pId", pId);
    handleShowTicket("", "new", pId);
  }

  function handleDateClick(info) {
    console.log("date" + info.date);
    handleShowTicket("", "new", undefined, info.date);
  }

  // a custom render function
  function renderEventContent(eventInfo) {
    return (
      <>
        <Card
          style={{ width: "100%" }}
          bg={eventInfo?.event?.extendedProps?.planned ? "info" : "light"}
          text={eventInfo?.event?.extendedProps?.planned ? "light" : "dark"}
          onClick={
            !eventInfo?.event?.extendedProps?.planned
              ? (event) =>
                  handleShowTicket(
                    event,
                    eventInfo?.event?.extendedProps?.ticketId,
                    eventInfo?.event?.extendedProps?.hasOwnProperty(
                      "plannedId"
                    ) && eventInfo?.event?.extendedProps?.plannedId
                  )
              : undefined
          }
        >
          <Card.Header>
            <strong>
              {eventInfo.timeText}{" "}
              {
                locations.find(
                  (l) => l.id === eventInfo?.event?.extendedProps?.location
                )?.name
              }
            </strong>
          </Card.Header>
          <Card.Body
            style={{
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
          >
            <Card.Text>
              {/* {!eventInfo?.event?.extendedProps?.planned && <div onClick={(event) => handleShowTicket(event, eventInfo?.event?.extendedProps?.ticketId, eventInfo?.event?.extendedProps?.hasOwnProperty("plannedId") &&eventInfo?.event?.extendedProps?.plannedId)}><b>Szerkesztés</b></div>}
          {!eventInfo?.event?.extendedProps?.planned && <div onClick={(event) => handleShowTicket(event, eventInfo?.event?.extendedProps?.ticketId, eventInfo?.event?.extendedProps?.hasOwnProperty("plannedId") &&eventInfo?.event?.extendedProps?.plannedId)}><b>Szerkesztés</b></div>} */}
              {eventInfo.event.title}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
              >
                {eventInfo?.event?.extendedProps?.planned && (
                  <PlusCircle
                    onClick={(event) =>
                      handleAddPlannedTicket(
                        event,
                        eventInfo?.event?.extendedProps?.plannedId
                      )
                    }
                  />
                )}
              </div>
              {eventInfo?.event?.extendedProps?.hasOwnProperty("delegatedTo") &&
                eventInfo?.event?.extendedProps.delegatedTo.length > 0 && (
                  <>
                    <hr />
                    <strong>Felelős: </strong>
                    {eventInfo?.event?.extendedProps?.hasOwnProperty(
                      "delegatedTo"
                    ) &&
                      eventInfo?.event?.extendedProps?.delegatedTo.map((k) => (
                        <div>{users?.find((u) => u.id === k)?.name}</div>
                      ))}
                  </>
                )}
            </Card.Text>
          </Card.Body>

          {!eventInfo?.event?.extendedProps?.planned && (
            <Card.Footer>
              <div
                className={statusSetStyle(
                  eventInfo?.event?.extendedProps?.statusId
                )}
              >
                {eventInfo?.event?.extendedProps?.hasOwnProperty("statusId") &&
                statuses.find(
                  (s) => s.sId === eventInfo?.event?.extendedProps?.statusId
                ) &&
                statuses
                  .find(
                    (s) => s.sId === eventInfo?.event?.extendedProps?.statusId
                  )
                  .hasOwnProperty("names") &&
                statuses
                  .find(
                    (s) => s.sId === eventInfo?.event?.extendedProps?.statusId
                  )
                  .names.find((l) => l.id === lang) &&
                statuses
                  .find(
                    (s) => s.sId === eventInfo?.event?.extendedProps?.statusId
                  )
                  .names.find((l) => l.id === lang)
                  .hasOwnProperty("name")
                  ? statuses
                      .find(
                        (s) =>
                          s.sId === eventInfo?.event?.extendedProps?.statusId
                      )
                      .names.find((l) => l.id === lang).name
                  : ""}
              </div>
            </Card.Footer>
          )}
        </Card>
      </>
    );
  }

  const calendarRef = React.createRef();

  return (
    tickets &&
    planned &&
    assetsArray &&
    users &&
    userDetails &&
    assetsArray &&
    externals && (
      <div className="p-4">
        {console.log("ticketDeadlines", ticketDeadlines)}
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            bootstrap5Plugin,
            rrulePlugin,
            interactionPlugin,
          ]}
          themeSystem="bootstrap5"
          initialView={isMobile ? "dayGridDay" : "dayGridWeek"}
          headerToolbar={{
            left: "today,prev,next",
            center: "title",
            right: "dayGridWeek,dayGridDay,timeGridWeek,timeGridDay",
          }} // user can switch between the two
          weekends={true}
          events={ticketDeadlines}
          views={{
            dayGridDay: {
              eventContent: renderEventContent,
            },
            dayGridWeek: {
              eventContent: renderEventContent,
            },
          }}
          locales={[huLocale]}
          locale="hu"
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
          }}
          dateClick={handleDateClick}
          ref={calendarRef}
          contentHeight="auto"
        />
        {currentTicket?.hasOwnProperty("tId") && (
          <Modal
            show={showTicket}
            onHide={handleCloseTicket}
            className={"modal-xl"}
          >
            <Modal.Header closeButton>
              <Modal.Title>Feladat szerkesztése</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                <EditTicket
                  users={users}
                  userDetails={userDetails}
                  tId={currentTicket?.tId}
                  plannedId={currentTicket?.plannedId}
                  planned={planned}
                  assetsArray={assetsArray}
                  dateClicked={currentTicket?.dateClicked}
                  tickets={tickets}
                  handleCloseTicket={handleCloseTicket}
                  externals={externals}
                  statuses={statuses}
                />
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseTicket}>
                Bezárás
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    )
  );
}

export default MainCalendar;
