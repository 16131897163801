import React from "react";
import axios from "axios";
import { db } from "./firebase";
import { useState, useEffect, useMemo } from "react";
import { langs } from "./Common";
import { XSquareFill, Send } from "react-bootstrap-icons";
import {
  onSnapshot,
  updateDoc,
  collection,
  query,
  addDoc,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  ProgressBar,
  Image,
  Modal
} from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { sendTicketEmail } from "./sendEmail";
import { generateId } from "./generateId";

function EditTicket(props) {
  var tId = props.tId ? props.tId : "";
  var plannedId = props.plannedId;
  var users = props.users;
  var userDetails = props.userDetails;
  var planned = props.planned;
  var dateClicked = props.dateClicked;
  var assetsArray = props.assetsArray;
  var setShowToast = props.setShowToast;
  var handleCloseTicket = props.handleCloseTicket;
  var tickets = props.tickets;
  var externals = props.externals;
  var statuses = props.statuses;


/*   const [statuses, setStatuses] = useState();
 */ 
  const [ticket, setTicket] = useState();
  const [lang, setLang] = useState(langs[0].id);
  const [newComment, setNewComment] = useState("");
  const [locations, setLocations] = useState([]);
  const [locationsVisible, setLocationsVisible] = useState([]);
  const [usersArray, setUsersArray] = useState([]);
  const [externalsArray, setExternalsArray] = useState([]);

  const [images, setImages] = useState([]);
  const [commentImages, setCommentImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [currentImage, setCurrentImage] = useState();

  const [showImage, setShowImage] = useState(false);
  const handleCloseImage = () => {
    setCurrentImage("");
    setShowImage(false)
  };
  const handleShowImage = (event, imageId) => {
    setCurrentImage(imageId);
    setShowImage(true);
  }

  function onUpload(event) {
    event.preventDefault();
    var selectedLocalFile = event.target.files;

    const data = new FormData();

    for (let i = 0; i < selectedLocalFile.length; i++) {
      data.append("file[]", selectedLocalFile[i]);
    }

    console.log("selectedFile", selectedLocalFile);
    axios({
      method: "post",
      url: "https://k.ren.hu/upload.php",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        let percentComplete = progressEvent.loaded / progressEvent.total;
        percentComplete = parseInt(percentComplete * 100);
        setUploadProgress(percentComplete);
      },
    })
      .then(function (response) {
        addImage(response.data);
        console.log(response);
        /*         addImage(); */
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  function addImage(filesArray) {
    const dbRef = collection(db, "images");
    filesArray.map((f) => {
      addDoc(dbRef, {
        filename: f.filename,
        dateAdded: Timestamp.fromDate(new Date()),
      })
        .then((docRef) => {

          var tempArray = commentImages;
          tempArray.push(docRef.id);
          setCommentImages([...tempArray]);
          console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  var defaultLocation = userDetails?.defaults?.hasOwnProperty("defaultLocation")
    ? userDetails.defaults?.defaultLocation
    : "";
  
  var dateNow = new Date(Date.now());

  const newTicketData = {
    title: plannedId
      ? assetsArray?.find(
        (a) => a.id === planned?.find((x) => x.id === plannedId)?.asset
      ).name + " karbantartása"
      : "",
    dateAdded: Timestamp.fromDate(new Date()),
    createdBy: users?.find((x) => x?.uId === userDetails?.uId).id,
    ...(!plannedId && { location: defaultLocation }),
    ...(plannedId && { plannedId: plannedId }),
    statusId: 0,
    comments: [],
    delegatedTo: [],
    /*  Gábor kérésére mindig van deadline */
    hasDeadline: true,
    deadline: plannedId
      ? dateNow.setMinutes(0) / 1000
      : dateClicked
        ? dateClicked.setHours(12) / 1000
        : (dateNow.setMinutes(0) + 86400000) / 1000, // +1 nap,
    serialNo: Math.max(...tickets?.map((t) => t.hasOwnProperty("serialNo") && t.serialNo)) + 1
  };

  function copyDefaults() {
    if (userDetails?.defaults?.hasOwnProperty("visibleLocationsInTicketList"))
      setLocationsVisible(userDetails.defaults.visibleLocationsInTicketList);
  }

  useEffect(() => copyDefaults(), [userDetails]);

  function createDelegatedArray() {
    var tempArray = [];
    ticket?.delegatedTo?.map((d) => {
      tempArray.push({ label: users.find((u) => u.id === d)?.name, value: d });
    });
    return tempArray;
  }

  function createDelegatedExternalsArray() {
    var tempArray = [];
    ticket?.delegatedExternalsTo?.map((d) => {
      tempArray.push({
        label: externals.find((u) => u.id === d)?.name,
        value: d,
      });
    });
    return tempArray;
  }

  
  function createUsersArray() {
    var tempArray = [];
    users.map((d) => {
      tempArray.push({ label: d.name, value: d.id });
    });
    setUsersArray([...tempArray]);
  }
  useEffect(() => createUsersArray(), [ticket]);

  function createExternalsArray() {
    var tempArray = [];
    externals.map((d) => {
      tempArray.push({ label: d.name, value: d.id });
    });
    setExternalsArray([...tempArray]);
  }
  useEffect(() => createExternalsArray(), [ticket]);

  function ticketData() {
    if (tId !== "new") {
      const docRef = doc(db, "tickets", tId);
      const unsubscribe = onSnapshot(docRef, (doc) => {
        setTicket(doc.data());
        /*  ahhoz, hogy emailt tudjunk küldeni annak, akinek kiosztjuk a munkát, kell tudjuk, hogy eredetileg kinek volt kiosztva. */
        /*  így nem küldünk ki minden változtatásnál e-mailt, csak akkor, ha elmentik a ticketet */
        /*  doc.data().hasOwnProperty("delegatedTo") && setOriginalDelegated([...doc.data().delegatedTo]);*/
      });
      return unsubscribe;
    } else {
      setTicket(newTicketData);
    }
  }
  useEffect(() => ticketData(), []);

  function locationsData() {
    const q = query(collection(db, "locations"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setLocations([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => locationsData(), []);

/*   function statusData() {
    const docRef = doc(db, "other", "statuses");
    getDoc(docRef).then((doc) => {
      setStatuses(Object.values(doc.data()));
    });
  }
  useEffect(() => statusData(), [ticket]); */

  const q = query(collection(db, "images"));
  function imagesData() {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setImages(newData);
    });
    return unsubscribe;
  }

  useEffect(() => imagesData(), []);

  function logChanges(ticketId) {
    const logRef = collection(db, "logs");
    var logData = [];

    if (tId === "new") {
      logData = {
        changes: [{ action: "newTicket" }],
        user: userDetails?.id,
        ticket: ticketId,
        dateAdded: Timestamp.fromDate(new Date()),
      };
    if (Object.keys(logData).length > 0) {
        addDoc(logRef, logData)
          .then((docRef) => {
            console.log(docRef.id); //
          })
          .catch((error) => {
            console.log(error);
          })
    }
    } else {
      /* megnézzük, hogy változott-e a ticket */
      const originalRef = doc(db, "tickets", tId);
      getDoc(originalRef).then((docSnap) => {
        var originalData = docSnap.data();
        var diffArray = Object.keys(ticket).filter((key) => ticket[key]?.toString() !== originalData[key]?.toString()
        )
        logData = {
          user: userDetails?.id,
          ticket: tId,
          dateAdded: Timestamp.fromDate(new Date()),
          changes: []
        };
        diffArray.map((item) => {
          if (originalData[item] !== ticket[item]) {
            logData?.changes.push({
              action: item,
              from: originalData[item],
              to: ticket[item],
              
            });
          }
        }
        
        )
        console.log("logData", logData);

        if (Object.keys(logData).length > 0) {
            addDoc(logRef, logData)
              .then((docRef) => {
                console.log(docRef.id); //
              })
              .catch((error) => {
                console.log(error);
              })
        }

        }
      )
    }
    
  }

function handleUpdateTicket(event) {
   event.preventDefault();
   
  if (tId !== "new") {
    logChanges();
    /* megnézzük, hogy van-e új embernek kiosztva a feladat. ha igen, küldünk emailt */
    const originalRef = doc(db, "tickets", tId);
    getDoc(originalRef).then((docSnap) => {
      var originalData = docSnap.data();
      var originalDelegated = originalData.hasOwnProperty("delegatedTo")
        ? originalData.delegatedTo
        : [];
      console.log("originalDelegated", originalDelegated);
      var newAssigned = ticket.delegatedTo?.filter(
        (x) => !originalDelegated?.includes(x)
      );
      console.log("newAssinged", newAssigned);
      newAssigned?.map((userId) => {
        sendTicketEmail(
          "assignedTicket",
          users?.find((u) => u.id === userId)?.email,
          ticket?.title,
          ticket?.deadline,
          locations.find((l) => l.id === ticket?.location)?.name
        );
      });

      const docRef = doc(db, "tickets", tId);
      updateDoc(docRef, {
        ...ticket,
      })
        .then((docRef) => {
          setShowToast(true);
          console.log("Document field updated");
        })
        .catch((error) => {
          console.log(error);
        });
    });
    
  } else {
      const dbRef = collection(db, "tickets");

      addDoc(dbRef, ticket)
        .then((docRef) => {
          /*  navigate("/tickets"); */
          setShowToast(true);
          sendTicketEmail(
            "newTicket",
            "muszak@visegradtours.hu, zeller.robert@visegradtours.hu",
            ticket?.title,
            ticket?.deadline,
            locations.find((l) => l.id === ticket?.location)?.name
          );
          console.log(docRef.id); //
          
          ticket.delegatedTo?.map((userId) => {
            sendTicketEmail(
              "assignedTicket",
              users?.find((u) => u.id === userId)?.email,
              ticket?.title,
              ticket?.deadline,
              locations.find((l) => l.id === ticket?.location)?.name
            );
          });
        })
        .catch((error) => {
          console.log(error);
        });
   }
   handleCloseTicket();
  }

  function timestampToDate(ts) {
    ts = new Date(ts * 1000);
    return (
      ts.toLocaleString("en-EN", { year: "numeric" }) +
      "-" +
      ts.toLocaleString("en-EN", { month: "2-digit" }) +
      "-" +
      ts.toLocaleString("en-EN", { day: "2-digit" })
    );
  }

  function timestampToTime(ts) {
    ts = new Date(ts * 1000);
    return ts.toLocaleTimeString("hu-HU", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  function handleChangeTime(event) {
    var newTime = event.target.value;
    var ts = new Date(ticket.deadline * 1000);
    ts.setHours(newTime.substr(0, 2));
    ts.setMinutes(newTime.substr(3, 2));
    setTicket({ ...ticket, deadline: Date.parse(ts) / 1000 });
  }

    function handleChangeStartTime(event) {
      var newTime = event.target.value;
      var ts = new Date(ticket.startDate * 1000);
      ts.setHours(newTime.substr(0, 2));
      ts.setMinutes(newTime.substr(3, 2));
      setTicket({ ...ticket, startDate: Date.parse(ts) / 1000 });
    }

  function handleAddComment(event) {
    event.preventDefault();
    addComment();
   /*  var tempComments = ticket.comments;

    /* type 0 - komment, type 1 - statuszvaltas
    tempComments.push({
      content: newComment,
      dateAdded: Timestamp.fromDate(new Date()),
      createdBy: users?.find((x) => x?.uId === userDetails?.uId).id,
      commentId: generateId(),
      type: 0,
      images: commentImages,
    });
    setTicket({ ...ticket, comments: tempComments });
    setNewComment("");
    setCommentImages([]); */
  }

  function addComment() {
    var tempComments = ticket.comments;

    /* type 0 - komment, type 1 - statuszvaltas */
    tempComments.push({
      content: newComment,
      dateAdded: Timestamp.fromDate(new Date()),
      createdBy: users?.find((x) => x?.uId === userDetails?.uId).id,
      commentId: generateId(),
      type: 0,
      images: commentImages,
    });
    setTicket({ ...ticket, comments: tempComments });
    setNewComment("");
    setCommentImages([]);

  }



  function handleDeleteComment(event, commentId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd?")) {
      var tempArray = [...ticket.comments];
      tempArray = tempArray.filter((x) => x.commentId !== commentId);
      setTicket({ ...ticket, comments: tempArray });
    }
  }

  function handleChangeStatus(event) {
    var newStatus = parseInt(event.target.value, 10);
    var tempComments = ticket.comments;

    /* type 0 - komment, type 1 - statuszvaltas */
    tempComments.push({
      dateAdded: Timestamp.fromDate(new Date()),
      createdBy: users?.find((x) => x?.uId === userDetails?.uId).id,
      commentId: generateId(),
      type: 1,
      fromStatus: ticket.statusId,
      toStatus: newStatus,
    });
    setTicket({ ...ticket, comments: tempComments, statusId: newStatus });
  }

  function handleChangeDelegated(event) {
    var ticketDelegatedArray = event.map((v) => v.value);
    setTicket({ ...ticket, delegatedTo: ticketDelegatedArray });
  }

  function handleChangeDelegatedExternals(event) {
    var ticketDelegatedExternalsArray = event.map((v) => v.value);
    setTicket({ ...ticket, delegatedExternalsTo: ticketDelegatedExternalsArray });
  }

  function handleChangeComment(event) {
    setNewComment(event.target.value);
  }

  return (
    <div className="EditTicket">
      {console.log("ticket", ticket)}
      {console.log("statuses", statuses)}
      {console.log("externals", externals)}
      {console.log("externalsArray", externalsArray)}
      {ticket && statuses && externals && externalsArray && (
        <>
          <Container>
            <Form>
              {plannedId && (
                <>
                  <Form.Group as={Row} className="pb-1 align-items-center">
                    <Form.Label column sm={2}>
                      Eszköz neve
                    </Form.Label>
                    <Col sm={4} xs={12}>
                      <strong>
                        {
                          assetsArray?.find(
                            (a) =>
                              a.id ===
                              planned?.find((x) => x.id === plannedId)?.asset
                          )?.name
                        }
                      </strong>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pb-1 align-items-center">
                    <Form.Label column sm={2} xs={12}>
                      Eszköz típusa
                    </Form.Label>
                    <Col sm={4} xs={12} className="pb-2">
                      {
                        assetsArray?.find(
                          (a) =>
                            a.id ===
                            planned?.find((x) => x.id === plannedId)?.asset
                        )?.type
                      }
                    </Col>
                  </Form.Group>
                </>
              )}

              <Form.Group as={Row} className="pb-1 pt-1  align-items-center">
                <Form.Label column sm={2} xs={12}>
                  Feladat címe
                </Form.Label>
                <Col sm={4} xs={12} className="pb-1 pt-1">
                  {/* Ha egy planned event-ből jön, akkor annak a nevét rakja be a ticketbe */}
                  <Form.Control
                    disabled={plannedId}
                    value={ticket.title}
                    onChange={(event) =>
                      setTicket({ ...ticket, title: event.target.value })
                    }
                  />
                </Col>
              </Form.Group>
              {/*    csak akkor legyen választható, ha az adott userhez több helyszín is tartozhat */}
              {userDetails?.locations?.length > 1 && (
                <Form.Group as={Row} className="pb-1 pt-1 align-items-center">
                  <Form.Label column sm={2} xs={12}>
                    Helyszín
                  </Form.Label>
                  <Col sm={4} xs={12}>
                    <Form.Select
                      disabled={plannedId}
                      id="locationId"
                      aria-label="Location"
                      onChange={(event) =>
                        setTicket({ ...ticket, location: event.target.value })
                      }
                      value={
                        plannedId
                          ? assetsArray?.find(
                              (a) =>
                                a.id ===
                                planned?.find((x) => x.id === plannedId)?.asset
                            ).name
                          : ticket.hasOwnProperty("location")
                          ? ticket.location
                          : "label"
                      }
                    >
                      <option key="default" value="label" disabled>
                        Válassz...
                      </option>
                      {userDetails?.locations?.map((l) => (
                        <option key={l} value={l}>
                          {locations.find((k) => k.id === l)?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              )}

              {locations
                .find((x) => x.id === ticket.location)
                ?.hasOwnProperty("rooms") && (
                <Form.Group as={Row} className="pb-1 pt-1 align-items-center">
                  <Form.Label column sm={2} xs={12}>
                    Szoba vagy részleg?
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Check
                      inline
                      checked={
                        ticket.hasOwnProperty("room") ? ticket.room : false
                      }
                      label="Szoba"
                      name="group1"
                      type="radio"
                      id="room"
                      onChange={(event) =>
                        setTicket({
                          ...ticket,
                          room: true,
                        })
                      }
                    />
                    <Form.Check
                      inline
                      checked={
                        ticket.hasOwnProperty("room") ? !ticket.room : false
                      }
                      label="Részleg"
                      name="group1"
                      type="radio"
                      id="sublocation"
                      onChange={(event) =>
                        setTicket({
                          ...ticket,
                          room: false,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
              )}

              {locations
                .find((x) => x.id === ticket.location)
                ?.hasOwnProperty("sublocations") &&
                ticket.hasOwnProperty("room") &&
                ticket.room === false && (
                  <Form.Group as={Row} className="pb-1 pt-1 align-items-center">
                    <Form.Label column sm={2} xs={12}>
                      Részleg
                    </Form.Label>
                    <Col sm={4} xs={12}>
                      <Form.Select
                        id="sublocation"
                        aria-label="SubLocation"
                        onChange={(event) =>
                          setTicket({
                            ...ticket,
                            sublocation: event.target.value,
                          })
                        }
                        value={
                          ticket.hasOwnProperty("sublocation")
                            ? ticket.sublocation
                            : "label"
                        }
                      >
                        <option key="default" value="label" disabled>
                          Válassz...
                        </option>
                        {locations
                          .find((x) => x.id === ticket.location)
                          ?.sublocations.map((l) => (
                            <option key={l.id} value={l.id}>
                              {l.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                )}

              {locations
                .find((x) => x.id === ticket.location)
                ?.hasOwnProperty("rooms") &&
                ticket.hasOwnProperty("room") &&
                ticket.room === true && (
                  <>
                    <Form.Group
                      as={Row}
                      className="pb-1 pt-1 align-items-center"
                    >
                      <Form.Label column sm={2} xs={12}>
                        Szobaszám
                      </Form.Label>
                      <Col sm={4} xs={12}>
                        <Form.Select
                          id="roomNumber"
                          aria-label="RoomNumber"
                          onChange={(event) =>
                            setTicket({ ...ticket, roomNo: event.target.value })
                          }
                          value={
                            ticket.hasOwnProperty("roomNo")
                              ? ticket.roomNo
                              : "label"
                          }
                        >
                          <option key="default" value="label" disabled>
                            Válassz...
                          </option>
                          {locations
                            .find((x) => x.id === ticket.location)
                            ?.rooms.map((l) => (
                              <option key={l} value={l}>
                                {l}
                              </option>
                            ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </>
                )}

              <Form.Group as={Row} className="pb-1 pt-1 align-items-center">
                <Form.Label column sm={2} xs={12}>
                  Státusz
                </Form.Label>
                <Col sm={4} xs={12}>
                  <Form.Select
                    id="statusId"
                    aria-label="Status"
                    onChange={handleChangeStatus}
                    value={
                      ticket.hasOwnProperty("statusId")
                        ? ticket.statusId
                        : "label"
                    }
                  >
                    <option key="default" value="label" disabled>
                      Válassz...
                    </option>
                    {statuses.map((s) => (
                      <option key={s.sId} value={s.sId}>
                        {s.hasOwnProperty("names") &&
                        s.names.find((l) => l.id === lang) &&
                        s.names
                          .find((l) => l.id === lang)
                          .hasOwnProperty("name")
                          ? s.names.find((l) => l.id === lang).name
                          : ""}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>

              {userDetails?.hasOwnProperty("level") &&
                userDetails.level <= 3 && (
                  <>
                    <Form.Group
                      as={Row}
                      className="pb-1 pt-1 align-items-center"
                      controlId="startDate"
                    >
                      <Form.Label column sm={2} xs={12}>
                        Kezdés
                      </Form.Label>
                      <Col sm={2} xs={12} className="pb-1">
                        <Form.Control
                          disabled={
                            userDetails?.hasOwnProperty("level") &&
                            userDetails.level > 3
                          }
                          onChange={(event) =>
                            setTicket({
                              ...ticket,
                              startDate: Date.parse(event.target.value) / 1000,
                            })
                          }
                          type="date"
                          value={timestampToDate(ticket.startDate)}
                        ></Form.Control>
                      </Col>
                      {/*                 <Col sm={6} xs={0}></Col>
                <Col sm={2} xs={0}></Col> */}
                      <Col sm={2} xs={12} className="pb-1">
                        <Form.Control
                          disabled={
                            userDetails?.hasOwnProperty("level") &&
                            userDetails.level > 3
                          }
                          onChange={(event) => handleChangeStartTime(event)}
                          type="time"
                          value={timestampToTime(ticket.startDate)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    {/*  Gábor kérésére kivettük azt, hogy ne lehessen határideje */}

                    {/* <Form.Group as={Row} className="mb-4">
                        <Form.Label column sm={2}>
                          Határidős?
                        </Form.Label>
                        <Col sm={6}>
                          <Form.Check type="checkbox" id="hasDeadline" onChange={(event) => setTicket({...ticket,hasDeadline : event.target.checked})} checked={ticket.hasDeadline} />
                        </Col>
                              </Form.Group> */}
                    {/*  {ticket.hasDeadline && */}
                    <Form.Group
                      as={Row}
                      className="pb-1 align-items-center"
                      controlId="deadline"
                    >
                      <Form.Label column sm={2} xs={12}>
                        Határidő
                      </Form.Label>
                      <Col sm={2} xs={12} className="pb-1">
                        <Form.Control
                          disabled={
                            userDetails?.hasOwnProperty("level") &&
                            userDetails.level > 3
                          }
                          onChange={(event) =>
                            setTicket({
                              ...ticket,
                              deadline: Date.parse(event.target.value) / 1000,
                            })
                          }
                          type="date"
                          value={timestampToDate(ticket.deadline)}
                        ></Form.Control>
                      </Col>
                      <Col sm={2} xs={12} className="pb-1">
                        <Form.Control
                          disabled={
                            userDetails?.hasOwnProperty("level") &&
                            userDetails.level > 3
                          }
                          onChange={(event) => handleChangeTime(event)}
                          type="time"
                          value={timestampToTime(ticket.deadline)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    {/* } */}
                    <Form.Group
                      as={Row}
                      className="pb-1"
                      controlId="delegatedTo"
                    >
                      <Form.Label column sm={2} xs={12}>
                        Felelős
                      </Form.Label>
                      <Col sm={4} xs={12} className="pb-1">
                        <MultiSelect
                          disabled={
                            userDetails?.hasOwnProperty("level") &&
                            userDetails.level > 3
                          }
                          hasSelectAll={false}
                          options={usersArray}
                          value={createDelegatedArray()}
                          onChange={(event) => handleChangeDelegated(event)}
                          labelledBy="Select"
                          overrideStrings={{
                            allItemsAreSelected: "Mindenki kijelölve",
                            search: "Keresés",
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="pb-1"
                      controlId="delegatedTo"
                    >
                      <Form.Label column sm={2} xs={12}>
                        Szolgáltató
                      </Form.Label>
                      <Col sm={4} xs={12} className="pb-1">
                        <MultiSelect
                          hasSelectAll={false}
                          options={externalsArray}
                          value={createDelegatedExternalsArray()}
                          onChange={(event) => handleChangeDelegatedExternals(event)}
                          labelledBy="Select"
                          overrideStrings={{
                            allItemsAreSelected: "Mindenki kijelölve",
                            search: "Keresés",
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </>
                )}

              <Row className="pb-1">
                <Form.Label column sm={2} xs={12}>
                  Megjegyzés hozzáadása
                </Form.Label>
                <Col sm={4} xs={12}>
                  <Card className="pb-1">
                    <Card.Header className="text-start">
                      <div>
                        <strong>Új megjegyzés</strong>
                      </div>
                    </Card.Header>
                    <Card.Body className="text-start">
                      <Row>
                        <Col>
                          <Form.Control
                            value={newComment}
                            as="textarea"
                            rows={3}
                            onChange={(event) => handleChangeComment(event)}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-2">
                        <Col>
                          <Form.Control
                            type="file"
                            multiple
                            id="userFile"
                            accept="image/*"
                            name="userFile"
                            onChange={onUpload}
                          />
                        </Col>
                      </Row>
                      {uploadProgress > 0 && uploadProgress < 100 && (
                        <Row className="pt-2">
                          <Col>
                            {uploadProgress > 0 && uploadProgress < 100 && (
                              <ProgressBar animated now={uploadProgress} />
                            )}
                            {uploadProgress === 100 && "Feltöltés kész"}
                          </Col>
                        </Row>
                      )}
                      {commentImages?.length !== 0 && (
                        <Row className="pt-2">
                          {commentImages?.map((i) => (
                            <Col>
                              <Image
                                src={
                                  "https://k.ren.hu/images/" +
                                  images
                                    ?.find((x) => x.id === i)
                                    ?.filename?.substring(
                                      0,
                                      images
                                        ?.find((x) => x.id === i)
                                        ?.filename?.lastIndexOf(".")
                                    ) +
                                  "_thumb.jpg"
                                }
                                rounded
                                thumbnail
                              />
                            </Col>
                          ))}
                        </Row>
                      )}

                      <Row className="pt-2 text-end">
                        <Col>
                          <Button
                            id="addComment"
                            onClick={handleAddComment}
                            variant="primary"
                            type="submit"
                          >
                            <Send /> Elküldés
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="pb-1">
                <Col sm={2} xs={12}></Col>
                <Col sm={4} xs={12}>
                  {ticket.comments
                    ?.sort(function (a, b) {
                      // dátum szerint sorrendben a commentek
                      var keyA = a.dateAdded.toDate();
                      var keyB = b.dateAdded.toDate();
                      if (keyA < keyB) return 1;
                      if (keyA > keyB) return -1;
                      return 0;
                    })
                    .map((c) => (
                      <Card className="mt-2" bg={c.type === 1 ? "info" : ""}>
                        <Card.Header className="text-start">
                          <div>
                            <strong>
                              {c.hasOwnProperty("createdBy")
                                ? users?.find((x) => x.id === c.createdBy)?.name
                                : ""}
                            </strong>
                          </div>
                          <div className="text-muted">
                            {userDetails?.hasOwnProperty("level") &&
                              userDetails.level === 0 && (
                                <XSquareFill
                                  color="#0d6efd"
                                  onClick={(event) =>
                                    handleDeleteComment(event, c.commentId)
                                  }
                                />
                              )}

                            {c.dateAdded.toDate().toLocaleDateString("hu-HU") +
                              ", " +
                              c.dateAdded.toDate().toLocaleTimeString("hu-HU")}
                          </div>
                        </Card.Header>
                        <Card.Body className="text-start">
                          <Card.Text>
                            {/* type 0 - komment, type 1 - statuszvaltas */}
                            {c.type === 0 && c.content}
                            {c.type === 1 &&
                              "Státusz átállítva:" +
                                statuses
                                  .find((s) => s.sId === c.fromStatus)
                                  .names.find((l) => l.id === lang).name +
                                " -> " +
                                statuses
                                  .find((s) => s.sId === c.toStatus)
                                  .names.find((l) => l.id === lang).name}
                          </Card.Text>
                          {c.hasOwnProperty("images") &&
                            c.images?.length != 0 &&
                            c.images?.map((i) => (
                              <Row>
                                <Col>
                                  <Image
                                    style={{ cursor: "pointer" }}
                                    src={
                                      "https://k.ren.hu/images/" +
                                      images
                                        ?.find((x) => x.id === i)
                                        ?.filename?.substring(
                                          0,
                                          images
                                            ?.find((x) => x.id === i)
                                            ?.filename?.lastIndexOf(".")
                                        ) +
                                      "_thumb.jpg"
                                    }
                                    rounded
                                    thumbnail
                                    onClick={(event) =>
                                      handleShowImage(event, i)
                                    }
                                  />
                                </Col>
                              </Row>
                            ))}
                        </Card.Body>
                      </Card>
                    ))}
                </Col>
              </Row>
              <Row className="pt-4 pb-4">
                <Col sm={2} xs={12}></Col>
                <Col sm={4} xs={12}>
                  <Button
                    id="saveButton"
                    disabled={
                      newComment !== "" ||
                      commentImages.length > 0 ||
                      ticket?.title === ""
                    }
                    onClick={handleUpdateTicket}
                    variant="primary"
                    type="submit"
                  >
                    Feladat mentése
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>

          <Modal
            show={showImage}
            onHide={handleCloseImage}
            className={"modal-xl"}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Image
                style={{ cursor: "pointer" }}
                src={
                  "https://k.ren.hu/images/" +
                  images
                    ?.find((x) => x.id === currentImage)
                    ?.filename?.substring(
                      0,
                      images
                        ?.find((x) => x.id === currentImage)
                        ?.filename?.lastIndexOf(".")
                    ) +
                  "_reduced.jpg"
                }
                fluid
                onClick={handleCloseImage}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
}

export default EditTicket;