import { Toast, ToastContainer } from "react-bootstrap";


function HVToast(props) {
    var txt = props.txt;
    var showToast = props.showToast;
    var setShowToast = props.setShowToast;
    const toggleShowToast = () => setShowToast(!showToast);
    return (
        <ToastContainer className="position-fixed" position="bottom-end">
        <Toast show={showToast} onClose={toggleShowToast} delay={2000} autohide>
            <Toast.Header>
                <strong className="me-auto">Hotel Visegrád CMMS</strong>
            </Toast.Header>
            <Toast.Body>{txt}</Toast.Body>
            </Toast>
        </ToastContainer>
           )
}

export default HVToast;