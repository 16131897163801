import React from "react";
import { Container, Button, Row, Col, Form, Modal } from "react-bootstrap";
import { FileEarmarkPlus } from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  query,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import EditExternal from "./EditExternal";
import EditExternalTags from "./EditExternalTags";
import HVToast from "./HVToast";

function Externals(props) {
  const userDetails = props.userDetails;
  const users = props.users;
  const [externals, setExternals] = useState([]);
  const [currentExternal, setCurrentExternal] = useState();
  const [tags, setTags] = useState();
  const [externalsViewSearch, setExternalsViewSearch] = useState();
  const [searchString, setSearchString] = useState("");
  const [showExternal, setShowExternal] = useState(false);
  const handleCloseExternal = () => {
    setShowExternal(false);
  };
  const handleShowExternal = (event, eId) => {
    event.preventDefault();
    setCurrentExternal(eId);
    setShowExternal(true);
  };

  const [showTags, setShowTags] = useState(false);
  const handleCloseTags = () => {
    setShowTags(false);
  };
  const handleShowTags = (event) => {
    event.preventDefault();
    setShowTags(true);
  };

  const [showToast, setShowToast] = useState(false);

  function externalsData() {
    const q = query(collection(db, "externals"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({ id: doc.id, ...doc.data() });
      });
      setExternals([...newData].sort((a, b) => a.name.localeCompare(b.name)));
    });
    return unsubscribe;
  }
  useEffect(() => externalsData(), []);

  useEffect(
    () => externals && setExternalsViewSearch([...externals]),
    [externals]
  );

  function handleDeleteExternal(event, dId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd a szolgáltatót?")) {
      const docRef = doc(db, "externals", dId);
      deleteDoc(docRef)
        .then((docRef) => {
          console.log("Document deleted");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

    function tagsData() {
    const q = query(collection(db, "externalTags"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newData = [];
      querySnapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setTags([...newData]);
    });
    return unsubscribe;
  }
  useEffect(() => tagsData(), []);

  function handleSearch(event) {
    console.log("viewSearch", externalsViewSearch)

        var searchStr = event.target.value;
        setSearchString(searchStr);
        var lowSearchStr = searchStr.toLowerCase();
        var tempArray = externals.filter(
          (x) => 
            x.name.toLowerCase().includes(lowSearchStr)
              || x.tags?.map((y) => { if (tags?.find((t) => t.id === y)?.tag?.toLowerCase().includes(lowSearchStr)) { return true } else { return false } }).indexOf(true) !== -1
          
        );
        setExternalsViewSearch([...tempArray]);
      }

  return (
    <div className="externals">
      <Container className="pt-4">
        <Form>
          <Row>
            {userDetails?.hasOwnProperty("level") && userDetails.level <= 5 && (
              <>
                <Col sm={2} xs={12} className="text-start align-middle">
                  <Button
                    onClick={(event) => handleShowExternal(event, "new")}
                    id="addTicket"
                    variant="primary"
                    type="submit"
                  >
                    <FileEarmarkPlus /> Új szolgáltató
                  </Button>
                </Col>
                <Col sm={3} xs={12}>
                  <Form.Control
                    placeholder="Keresés..."
                    value={searchString}
                    onChange={(event) => handleSearch(event)}
                  />
                </Col>
                <Col sm={2} xs={12}>
                  <Button
                    onClick={handleShowTags}
                    id="addTicket"
                    variant="primary"
                    type="submit"
                  >
                    <FileEarmarkPlus /> Címkék
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Form>
        {externals &&
          tags && externalsViewSearch &&
          externalsViewSearch.map((e, i) => (
            <Row key={i} className="pt-2 pb-2 border-secondary border-bottom">
              <Col className="d-flex justify-content-start align-items-center">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(event) => handleShowExternal(event, e.id)}
                >
                  {e.hasOwnProperty("name") ? e.name : ""}
                </div>
              </Col>
              <Col>
                {e?.tags?.map((tag) => {
                  return <div>{tags.find((t) => t.id === tag)?.tag}</div>;
                })}
              </Col>
              <Col>
                <Button
                  onClick={(event) => handleDeleteExternal(event, e.id)}
                  variant="primary"
                  type="submit"
                >
                  Törlés
                </Button>
              </Col>
            </Row>
          ))}
      </Container>

      <Modal
        show={showExternal}
        onHide={handleCloseExternal}
        className={"modal-xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Szolgáltató szerkesztése</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditExternal
            tags={tags}
            eId={currentExternal}
            users={users}
            userDetails={userDetails}
            showToast={showToast}
            setShowToast={setShowToast}
            handleCloseExternal={handleCloseExternal}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseExternal}>
            Bezárás
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  CÍMÉK SZERKESZTÉSE MODAL */}

      <Modal show={showTags} onHide={handleCloseTags} className={"modal-xl"}>
        <Modal.Header closeButton>
          <Modal.Title>Címkék szerkesztése</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditExternalTags
            users={users}
            userDetails={userDetails}
            showToast={showToast}
            setShowToast={setShowToast}
            handleCloseTags={handleCloseTags}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseTags}>
            Bezárás
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  /CÍMÉK SZERKESZTÉSE MODAL */}

      <HVToast
        txt="Szolgáltató mentve"
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </div>
  );
}

export default Externals;
