export function statusSetStyle(statusId) {
  switch (statusId) {
    case 0:
      return "status new";
    case 1:
      return "status inProgress";
    case 2:
      return "status done";
    default:
      return "";
  }
}
