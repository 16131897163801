import { initializeApp } from "firebase/app";
import { getFirestore, persistentLocalCache } from "firebase/firestore";
import { getAuth } from "firebase/auth";


const config = {
    apiKey: "AIzaSyA9b2bSKQK2nykcw765Osj0C3y_nuPowz8",
    authDomain: "hotel-visegrad-cmms.firebaseapp.com",
    projectId: "hotel-visegrad-cmms",
    storageBucket: "hotel-visegrad-cmms.appspot.com",
    messagingSenderId: "351888460346",
    appId: "1:351888460346:web:11fcdf85202867b8718303"
  };
const app = initializeApp(config, { localCache: persistentLocalCache() });

export const auth = getAuth(app);
// Get a reference to the database service
export const db = getFirestore(app);
