import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button} from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { db } from "./firebase";
import {
  onSnapshot,
  updateDoc,
  collection,
  addDoc,
  doc,
  Timestamp,
  query
} from "firebase/firestore";

import { generateId } from "./generateId";

function EditExternal(props) {
  const tags = props.tags;
  const users = props.users;
  const userDetails = props.userDetails;
  const setShowToast = props.setShowToast;
  const eId = props.eId;
  const [editable, setEditable] = useState();
  const [shouldSave, setShouldSave] = useState(false);

  const [external, setExternal] = useState();
/*   const [tags, setTags] = useState(); */
  const [addContactField, setAddContactField] = useState(false);
  const [contactsEdited, setContactsEdited] = useState();


  const newExternalData = {
    name: "",
    createdBy: users?.find((x) => x?.uId === userDetails?.uId).id,
    dateAdded: Timestamp.fromDate(new Date()),
    contacts: [],
  };

  const newContactData = {
    name: "",
    phone: "",
    email: ""
  }

   const [newContact, setNewContact] = useState(newContactData);

  function externalData() {
    if (eId !== "new") {
      const docRef = doc(db, "externals", eId);
      const unsubscribe = onSnapshot(docRef, (doc) => {
        setExternal(doc.data());
      });
      return unsubscribe;
    } else {
      setExternal(newExternalData);
    }
  }
  useEffect(() => externalData(), []);

  useEffect(() => { external && setContactsEdited([...external?.contacts]) }, [external])




  
  function updateExternal() {
    const docRef = doc(db, "externals", eId);
    updateDoc(docRef, {
      ...external,
    })
      .then((docRef) => {
        setShowToast(true);
        console.log("Document field updated");
      })
      .catch((error) => {
        console.log(error);
      });

  }

  function handleUpdateExternal(event) {
    event.preventDefault();

    if (eId !== "new") {
      updateExternal();
      
    } else {
      const dbRef = collection(db, "externals");
      console.log("externalAdd", external)
      addDoc(dbRef, external)
        .then((docRef) => {
          setShowToast(true);

          console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleUpdateContact(event, contactId) {
  
    event.preventDefault();
    var tempContacts = external.hasOwnProperty("contacts") ? [...external.contacts] : [];
    if (contactId === "new") {
      tempContacts.push({
        name: newContact.name,
        phone: newContact.phone,
        email: newContact.email,
        id: generateId()
      });
      setExternal({ ...external, contacts: tempContacts });
      setNewContact({});
    } else {
      setExternal({ ...external, contacts: contactsEdited });
      setEditable();
      setShouldSave(true)
    }
  }

  useEffect(() => {
    if (shouldSave) {
      updateExternal()
      setShouldSave(false)
    }
}
    , [external, shouldSave]);

  function handleChangeContact(event, contactId) {
    var target = event.target.id;
    var tempArray = [...contactsEdited]
    var tempContact = tempArray.find((c) => c.id === contactId);
    tempContact[target] = event.target.value;
    setContactsEdited([...tempArray])
  }
  
  function handleDeleteContact(event, contactId) {
     event.preventDefault();
    if (window.confirm("Biztosan törlöd ezt az adatot?")) {
      var tempArray = [...contactsEdited];
      tempArray = tempArray.filter((c) => c.id !== contactId);
      setContactsEdited([...tempArray]);
    }
  }
  
    function handleChangeTags(event) {
      var externalTagArray = event.map((v) => v.value);
      setExternal({ ...external, tags: externalTagArray });
    }
    
    

  return (
    external &&
    contactsEdited &&
    tags && (
      <div className="EditExternal">
        {console.log("externalRender", external)}
        <Container>
          <Form.Group as={Row} className="pb-4 pt-4">
            <Form.Label column sm={2}>
              Szolgáltató neve
            </Form.Label>
            <Col sm={4} className="pb-2">
              <Form.Control
                value={external.name}
                onChange={(event) =>
                  setExternal({ ...external, name: event.target.value })
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="pb-4 pt-4">
            <Form.Label column sm={2}>
              Szolgáltató címe
            </Form.Label>
            <Col sm={4} className="pb-2">
              <Form.Control
                value={external.address}
                onChange={(event) =>
                  setExternal({ ...external, address: event.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="pb-4 pt-4">
            <Form.Label column sm={2}>
              Szolgáltató központi telefonszáma
            </Form.Label>
            <Col sm={4} className="pb-2">
              <Form.Control
                value={external.phone}
                onChange={(event) =>
                  setExternal({ ...external, phone: event.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="pb-4 pt-4">
            <Form.Label column sm={2}>
              Szolgáltató központi e-mail címe
            </Form.Label>
            <Col sm={4} className="pb-2">
              <Form.Control
                value={external.email}
                onChange={(event) =>
                  setExternal({ ...external, email: event.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="delegatedTo">
            <Form.Label column sm={2} xs={12}>
              Címkék
            </Form.Label>
            <Col sm={4} xs={12} className="pb-2">
              <MultiSelect
                disabled={
                  userDetails?.hasOwnProperty("level") && userDetails.level > 3
                }
                hasSelectAll={false}
                /*  A Multiselect options-ába így kell beletenni az adatokat: label : a kiírt címke, value: az id */
                options={tags?.map((tag) => {
                  return { label: tag.tag, value: tag.id };
                })}
                value={
                  external?.hasOwnProperty("tags")
                    ? external?.tags?.map((tag) => {
                        return {
                          label: tags.find((t) => t.id === tag)?.tag,
                          value: tag,
                        };
                      })
                    : []
                }
                onChange={(event) => handleChangeTags(event)}
                labelledBy="Select"
                overrideStrings={{
                  allItemsAreSelected: "Mindenki kijelölve",
                  search: "Keresés",
                }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="pb-4 pt-4">
            <Col sm={12} xs={12}>
              <Button onClick={(event) => setAddContactField(!addContactField)}>
                Kapcsolattartó hozzáadása
              </Button>
            </Col>
          </Form.Group>

          {addContactField && (
            <Form.Group as={Row} className="pb-4 pt-4" controlId="new">
              <Col sm={3} xs={12}>
                <Col>
                  <Form.Control
                    placeholder="Név"
                    value={newContact.name}
                    onChange={(event) =>
                      setNewContact({
                        ...newContact,
                        name: event.target.value ? event.target.value : "",
                      })
                    }
                  />
                </Col>
              </Col>
              <Col sm={3} xs={12}>
                <Col>
                  <Form.Control
                    placeholder="Telefonszám"
                    value={newContact.phone}
                    onChange={(event) =>
                      setNewContact({
                        ...newContact,
                        phone: event.target.value ? event.target.value : "",
                      })
                    }
                  />
                </Col>
              </Col>
              <Col sm={3} xs={12}>
                <Col>
                  <Form.Control
                    placeholder="E-mail"
                    value={newContact.email}
                    onChange={(event) =>
                      setNewContact({
                        ...newContact,
                        email: event.target.value ? event.target.value : "",
                      })
                    }
                  />
                </Col>
              </Col>
              <Col sm={3} xs={12} className="d-flex align-items-end">
                <Col>
                  <Button
                    onClick={(event) => handleUpdateContact(event, "new")}
                  >
                    Kapcsolat mentése
                  </Button>
                </Col>
              </Col>
            </Form.Group>
          )}

          <Row className="pt-4 pb-4">
            <Col sm={12} xs={12}>
              Kapcsolattartók
            </Col>
          </Row>

          {/*  MEGLÉVŐ KAPCSOLATOK SZERKESZTÉSE */}

          {contactsEdited?.map((contact) => (
            <Form.Group as={Row} className="pb-2">
              <Col sm={3} xs={12}>
                <Col>
                  <Form.Control
                    id="name"
                    disabled={editable !== contact.id}
                    value={contact.name}
                    onChange={(event) => handleChangeContact(event, contact.id)}
                  />
                </Col>
              </Col>
              <Col sm={2} xs={12}>
                <Col>
                  <Form.Control
                    id="phone"
                    disabled={editable !== contact.id}
                    value={contact.phone}
                    onChange={(event) => handleChangeContact(event, contact.id)}
                  />
                </Col>
              </Col>
              <Col sm={3} xs={12}>
                <Col>
                  <Form.Control
                    id="email"
                    disabled={editable !== contact.id}
                    value={contact.email}
                    onChange={(event) => handleChangeContact(event, contact.id)}
                  />
                </Col>
              </Col>
              <Col sm={2} xs={12} className="d-flex align-items-end">
                <Col>
                  {editable !== contact.id ? (
                    <Button onClick={(event) => setEditable(contact.id)}>
                      Szerkesztés
                    </Button>
                  ) : (
                    <Button onClick={handleUpdateContact}>Mentés</Button>
                  )}
                </Col>
              </Col>
              <Col sm={2} xs={12} className="d-flex align-items-end">
                <Col>
                  <Button
                    onClick={(event) => handleDeleteContact(event, contact.id)}
                  >
                    Törlés
                  </Button>
                </Col>
              </Col>
            </Form.Group>
          ))}

          <Row className="pt-4 pb-4">
            {/*             <Col sm={2} xs={12}></Col> */}
            <Col sm={12} xs={12}>
              <Button
                id="saveButton"
                disabled={external?.name === ""}
                onClick={handleUpdateExternal}
                variant="primary"
                type="submit"
              >
                Szolgáltató mentése
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
}

export default EditExternal;
