/* planned types: 
0 - maint_inhouse
1 - external */

import { db } from "./firebase";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {
    collection,
    onSnapshot,
    query,
    addDoc,
    doc,
    deleteDoc, updateDoc
} from "firebase/firestore";
import React from "react";
import {
    Container, Button,
    Row,
    Col,
    Form,
    ListGroup,
    Offcanvas,
    Modal
} from "react-bootstrap";
import {
    XSquareFill, FileEarmarkPlus, StickyFill,
    Calendar
} from "react-bootstrap-icons";
import { langs } from "./Common";
import HVToast from "./HVToast";
import EditAsset from "./EditAsset";

function Assets(props) {
    const userDetails = props.userDetails;
    const users = props.users;
    const [assets,
        setAssets] = useState();
    const [assetsView,
        setAssetsView] = useState();
    const [assetStatuses,
        setAssetStatuses] = useState([]);
    const [assetSpares,
        setAssetSpares] = useState([]);
    const [locations,
        setLocations] = useState([]);
    const [lang,
        setLang] = useState(langs[0].id);
    const [locationsVisible,
        setLocationsVisible] = useState([]);
    const [showDrawer,
        setShowDrawer] = useState(false);
    const handleCloseDrawer = () => setShowDrawer(false);
    const handleShowDrawer = () => setShowDrawer(true);
    const [editable, setEditable] = useState([]);
    const [showNotes, setShowNotes] = useState(false);
    const handleCloseNotes = () => setShowNotes(false);
    const handleShowNotes = () => setShowNotes(true);

    const [showDates, setShowDates] = useState(false);
    const handleCloseDates = () => setShowDates(false);
    const handleShowDates = () => setShowDates(true);

    const [showAsset, setShowAsset] = useState(false);
    const handleCloseAsset = () => {
        setShowAsset(false)
    };
    const handleShowAsset = () => {
        setShowAsset(true);
    }

    const [showToast, setShowToast] = useState(false);


    const [currentAsset, setCurrentAsset] = useState("");
    const [searchString, setSearchString] = useState("");
    const [assetsViewSearch, setAssetsViewSearch] = useState();

    /* planned types: 
0 - inhouse
1 - external */

    const [dateType, setDateType] = useState(0);
    const [planned, setPlanned] = useState([]);


    function copyDefaults() {
        if (userDetails
            ?.defaults
                ?.hasOwnProperty("visibleLocationsInTicketList")) 
            setLocationsVisible(userDetails.defaults.visibleLocationsInTicketList);
        }
    
    useEffect(() => copyDefaults(), [userDetails]);

    useEffect(() => assets && setAssetsView([...assets]), [assets]);
    useEffect(() => assetsView && setAssetsViewSearch([...assetsView]), [assetsView]);

    function assetsData() {
        const q = query(collection(db, "assets"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setAssets([...newData].sort((a, b) => b.dateAdded - a.dateAdded));
        });
        return unsubscribe;
    }
    useEffect(() => assetsData(), []);

    function plannedData() {
        const q = query(collection(db, "planned"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setPlanned([...newData]);
        });
        return unsubscribe;
    }
    useEffect(() => plannedData(), []);


    function setEditableArray() {
        var tempArray = [];
        {console.log("assets", assets)}
        assets.map((a) => {
            tempArray.push({
                id: a.id,
                name: false,
                sublocation: false,
                status: false
            })
        })
        setEditable([...tempArray]);

    }
    useEffect(() => assets && setEditableArray(), [assets]);



       function assetsStatusesData() {
        const q = query(collection(db, "assetStatuses"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setAssetStatuses([...newData]);
        });
        return unsubscribe;
    }
    useEffect(() => assetsStatusesData(), []);

   function assetSparesData() {
        const q = query(collection(db, "assetSpares"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setAssetSpares([...newData]);
        });
        return unsubscribe;
    }
    useEffect(() => assetSparesData(), []);


    function locationsData() {
        const q = query(collection(db, "locations"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newData = [];
            querySnapshot.forEach((doc) => {
                newData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setLocations([...newData]);
        });
        return unsubscribe;
    }
    useEffect(() => locationsData(), []);


    function handleDeleteAsset(event, assetId) {
        event.preventDefault();
        if (window.confirm("Biztosan törlöd ezt az eszközt?")) {
            const docRef = doc(db, "assets", assetId);
            deleteDoc(docRef).then((docRef) => {
                // kitöröljük a hozzá tartozó tervezett karbantartásokat is az adatbázisból
                planned?.filter((x) => x.asset === assetId)?.map((p) => {
                const plannedRef = doc(db, "planned", p.id);
                deleteDoc(plannedRef).then((plannedRef) => {
                    console.log("All referring planned items deleted");
                }).catch((error) => {
                    console.log(error);
                });
                }
                )
                console.log("Document deleted");
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    function arraysSame(array1, array2) {
        return (array1.length == array2.length) && array1.every(function (element, index) {
            return element === array2[index];
        });
    }

    function handleChangeLocationsVisible(event, dId) {
        var tempActId = dId;
        var tempArray = [...locationsVisible]
        if (tempArray.indexOf(tempActId) === -1/* && event.target.checked */) {
            tempArray.push(tempActId);
        } else {
            tempArray = tempArray.filter((e) => e !== tempActId);
        }
        setLocationsVisible([...tempArray]);
    }

    /*  let tempArray = Array.from(event.target.selectedOptions, option => option.value); */

    function handleReOrder(event, param) {

        /* if (param === "title") {
      // ha név alapján kell szortírozni, akkor ez fut le. az arraysSame-mel leellenőrzi, hogy már szortírozva van-e
      // (tehát hogy az array megegyezik-e egy szortírozott array-jel, és ha igen, akkor fordítva szortírozza)
      if (arraysSame([...assetsView],[...assetsView].sort((a, b) => clients?.find((c) => c.id === a.clientId)?.name.localeCompare(clients?.find((c) => c.id === b.clientId)?.name))))
      {  setAssetsView([...assetsView].sort((a, b) => clients?.find((c) => c.id === b.clientId)?.name.localeCompare(clients?.find((c) => c.id === a.clientId)?.name)));}
      else {
        setAssetsView([...assetsView].sort((a, b) => clients?.find((c) => c.id === a.clientId)?.name.localeCompare(clients?.find((c) => c.id === b.clientId)?.name)));
      }
    }
    if (param === "dateAdded") {
       if (arraysSame([...assetsView],[...assetsView].sort((a, b) => b.dateAdded - a.dateAdded)))
      {  setAssetsView([...assetsView].sort((a, b) => a.dateAdded - b.dateAdded));}
      else {  setAssetsView([...assetsView].sort((a, b) => b.dateAdded - a.dateAdded));}

    } */
    }

/*     function changeFolder() {
        if (assets && folder) {
            var tempArray = [...assets];
            if (folder === 1) {
                tempArray = tempArray.filter(x => ((!x.hasOwnProperty("billed") || x.billed === false) & x.statusId != 3));
                setAssetsView([...tempArray]);
            }
            if (folder === 2) {
                tempArray = tempArray.filter(x => (x.billed === true || x.statusId === 3));
                if (onlyBilled === true) {
                    tempArray = tempArray.filter(x => (x.billed === true));
                }
                if (onlyLost === true) {
                    tempArray = tempArray.filter(x => (x.statusId === 3));
                }
                setAssetsView([...tempArray]);
            }
        }
    }

  useEffect(() => changeFolder(), [folder, onlyBilled, onlyLost]) */
    
    function handleChangeEditable(event, assetId) {
        var tempArray = editable;
        var columnId = event.target.id;
        var tempObjIndex = tempArray.findIndex((x) => x.id === assetId);
        tempArray[tempObjIndex] = {...tempArray[tempObjIndex], [columnId]: true}
        setEditable([...tempArray]);
    
    }

    function handleChangeField(event, assetId) {
        var type = event.target.id;
/*         var tempArray = assets;
        var tempIndex = tempArray.findIndex((x) => x.id === assetId); */
        const docRef = doc(db, "assets", assetId);

        if (type === "status") {
            updateDoc(docRef, {
              status: parseInt(event.target.value, 10)
          })
            .then((docRef) => {
                  console.log("Document field updated");
              })
              .catch((error) => {
                  console.log(error);
              });
    
        }

        if (type === "sublocation") {
            updateDoc(docRef, {
              sublocation: parseInt(event.target.value, 10)
          })
            .then((docRef) => {
                  console.log("Document field updated");
              })
              .catch((error) => {
                  console.log(error);
              });
    
        }

           if (type === "location") {
               updateDoc(docRef, {
                   location: event.target.value,
                   sublocation: ""
          })
            .then((docRef) => {
                  console.log("Document field updated");
              })
              .catch((error) => {
                  console.log(error);
              });
    
        }

        if (type === "maint_external") {
            updateDoc(docRef, {
                maint_external: event.target.checked
          })
            .then((docRef) => {
                  console.log("Document field updated");
              })
              .catch((error) => {
                  console.log(error);
              });
    
        }

         if (type === "maint_inhouse") {
            updateDoc(docRef, {
                maint_inhouse: event.target.checked
          })
            .then((docRef) => {
                  console.log("Document field updated");
              })
              .catch((error) => {
                  console.log(error);
              });
    
        }
   

    }

    function handleChangePlanned(event, assetId, dtype) {
        var currentPlanned = planned.find((x) => (x.asset === assetId && x.type === dtype));
        var plannedId = currentPlanned?.id;
        if (!currentPlanned.hasOwnProperty("freq")) 
            {currentPlanned.freq = "daily"}
        
        if (plannedId) {
            const docRef = doc(db, "planned", plannedId);
            updateDoc(docRef, {
                dtstart: currentPlanned?.dtstart,
                freq: currentPlanned?.freq
          })
            .then((docRef) => {
                  console.log("Document field updated");
              })
              .catch((error) => {
                  console.log(error);
              });
        }  
        else {
            const dbRef = collection(db, "planned");
 
                  addDoc(dbRef, currentPlanned)
                    .then((docRef) => {
                      console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
                  })
                  .catch((error) => {
                      console.log(error);
                  });
  }

        
    }

    function handleChangeNotes(event, assetId) {
        var tempArray = assets;
        var tempIndex = tempArray.findIndex((x) => x.id === assetId);
        tempArray[tempIndex].notes = event.target.value;
        setAssets([...tempArray]);

    }

    function handleChangeDates(event, assetId, dtype) {
        var type = event.target.id;
        var tempArray = planned;
       /*  Ha van az arrayban már ilyen Object, akkor az object indexe lesz, ha még nincs, akkor hozzáad egyet */
        var tempIndex = tempArray.findIndex((x) => (x.asset === assetId && x.type === dtype))
       /*  tempArray.push({}) -1; */
       if (tempIndex === -1) {
        tempIndex = tempArray.push({
            asset: assetId,
            type: dtype
        }) -1; 
    }

        if (type === "startDate") {
            tempArray[tempIndex].dtstart = Date.parse(event.target.value) / 1000
        }
        if (type === "freq") {
            tempArray[tempIndex].freq = event.target.value;
        }
        setPlanned([...tempArray]);

    }

    function handleShowNotesModal(event, assetId) {
        setCurrentAsset(assetId);
        handleShowNotes();
    }

    function handleShowCalModal(event, assetId, type) {
        setDateType(type);
        setCurrentAsset(assetId);
        handleShowDates();
    }

    function handleSearch(event) {
        var searchStr = event.target.value;
        setSearchString(searchStr);
        var lowSearchStr = searchStr.toLowerCase();
        var tempArray =  assetsView.filter(x => (x.name.toLowerCase().includes(lowSearchStr) || x.type.toLowerCase().includes(lowSearchStr)));
        setAssetsViewSearch([...tempArray]);
    }

    function timestampToDate(ts) {
        ts = new Date(ts * 1000);
        return ts.toLocaleString("en-EN", { year: "numeric" }) + "-" + ts.toLocaleString("en-EN", { month: "2-digit" }) + "-" + ts.toLocaleString("en-EN", { day: "2-digit" });
      }
    
      function timestampToTime(ts) {
        ts = new Date(ts * 1000);
        return ts.toLocaleTimeString("hu-HU", { hour: "2-digit", minute: "2-digit" });
      }


    return (
        <div>
            <Container className="m-2">
                <Row className="pt-4 pb-4">
 {/*                    <Col xs={4} md={4}>
                        <Form.Select
                            aria-label="Mappa"
                            value={folder}
                            onChange={(e) => setFolder(parseInt(e.target.value, 10))}>
                            <option value={1}>Aktuális</option>
                            <option value={2}>Archív</option>
                        </Form.Select>
            </Col>
 */}
                    {(userDetails?.hasOwnProperty("level") && userDetails.level <= 5) && <Col>
                      
                            <Button
                                onClick={handleShowAsset}
                                id="addAsset"
                                variant="primary"
                                type="submit">
                                <FileEarmarkPlus /> Új eszköz
                            </Button>
                     
                    </Col>}

                    <Col>
                        <Form.Control
                        placeholder="Keresés..."
                        value={searchString}
                        onChange={(event) => handleSearch(event)}
                      />
                    </Col>
                    <Col>

                        <Button variant="primary" onClick={handleShowDrawer}>
                            Beállítások
                        </Button>
                    </Col>

                    <Offcanvas show={showDrawer} onHide={handleCloseDrawer}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Beállítások</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>

                            {locations && userDetails && <ListGroup>
                                {locations.map((d, i) => (userDetails.locations
                                    ?.find(x => x === d.id) && <> <ListGroup.Item
                                        action
                                        value={d.id}
                                        active={locationsVisible && locationsVisible.indexOf(d.id) !== -1}
                                        onClick={(e) => handleChangeLocationsVisible(e, d.id)}>
                                        {d.hasOwnProperty("name")
                                            ? d.name
                                            : ""}
                                    </ListGroup.Item> </>
                  ))}

                            </ListGroup>}

                        </Offcanvas.Body>
                    </Offcanvas>

                </Row>
            </Container>
            <Container>
              
                <table className="ticketsTable">
                    <thead>
                        <tr>
                            <th colspan={2} onClick={(event) => handleReOrder(event, "title")}>Név</th>
                            {/*    <th>Státusz</th> */}
                            <th colspan={2}>Típus</th>
                            <th>Helyszín</th>
                            <th>Részleg</th>
                            {/* <th onClick={(event) => handleReOrder(event, "dateAdded")}>Létrehozva</th> */}
                            <th>Állapot</th>
                            <th>Házon<br/>belül</th>
                            <th>Külsős</th>
                            <th>Megjegy-<br />zések</th>
                      {/*       <th>Szoba</th>
                            <th>Felelősök</th>
                            <th>Határidő</th>
                            <th>Létrehozta</th> */}
                            {(userDetails?.hasOwnProperty("level") && userDetails?.level === 0) && <th>Törlés</th>}
                        </tr>
                    </thead>
                    <tbody>
  
                        {assetStatuses && assetSpares && userDetails && assets && assetsViewSearch && planned && assetsViewSearch?.map((p, i) => {

                     
                                if (p.hasOwnProperty("location") && (locationsVisible.indexOf(p.location) !== -1)) 
                                    return (

                                        <tr key={i}>
                                            <td colspan={2} data-label="Név">
                                                <Link
                                                    to="/asset"
                                                    state={{aId: p.id, users: users, userDetails: userDetails}}
                                                    style={{
                                                    textDecoration: "none",
                                                    color: "#000"
                                                }}><b>{p.name
                                                        ? p.name
                                                        : ""}
                                                </b></Link>
                                            </td>
                                            <td colspan={2} data-label="Típus">
                                                {p.type}
                                            </td>
                                            <td data-label="Helyszín" id="location" onDoubleClick={(event) => handleChangeEditable(event, p.id)}>
                                            
                                                
                                                    {editable?.find((x) => x.id === p.id)?.location ?
                                                    <Form.Select id="location" aria-label="Location" onChange={(event) => handleChangeField(event, p.id)} value={p.hasOwnProperty("location") ? p.location : "label"}>
                                                    
                                                            <option key="default" value="label" disabled>
                                                                Válassz...
                                                            </option>
                                                            {locations?.map((l) => (
                                                                <option key={l.id} value={l.id}>
                                                                    {l.name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    :
                                                    p.hasOwnProperty("location")
                                                    && locations.find((l) => l.id === p.location)?.name
                                                    }

                                            </td>
                                            <td data-label="Részleg" id="sublocation" onDoubleClick={locations.find((x) => x.id === p.location)?.hasOwnProperty("sublocations") ? (event) => handleChangeEditable(event, p.id) : undefined}>
                                                {editable?.find((x) => x.id === p.id)?.sublocation ?
                                                    <Form.Select id="sublocation" aria-label="Sublocation" onChange={(event) => handleChangeField(event, p.id)} value={(p.hasOwnProperty("sublocation") && p.sublocation !== "") ? p.sublocation : "label"}>
                                                    
                                                            <option key="default" value="label" disabled>
                                                                Válassz...
                                                            </option>
                                                            {locations.find((y) => y.id === p.location)?.sublocations?.map((l) => (
                                                                <option key={l.id} value={l.id}>
                                                                    {l.name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    :
                                                    p.hasOwnProperty("sublocation")
                                                    && locations.find((l) => l.id === p.location)?.hasOwnProperty("sublocations")
                                                    && locations.find((l) => l.id === p.location)?.sublocations?.find((s) => s.id === p.sublocation)?.name
                                                    }</td>
                                       {/*      <td data-label="Dátum">{new Date(p.dateAdded.seconds * 1000).toLocaleDateString("hu-HU")}</td> */}
                                            <td data-label="Állapot" id="status" onDoubleClick={(event) => handleChangeEditable(event, p.id)}>
                   
                                                
                                                    {editable?.find((x) => x.id === p.id)?.status ?
                                                    <Form.Select id="status" aria-label="Status" onChange={(event) => handleChangeField(event, p.id)} value={p.hasOwnProperty("status") ? p.status.toString() : "label"}>
                                                   
                                                            <option key="default" value="label" disabled>
                                                                Válassz...
                                                            </option>
                                                            {assetStatuses?.map((l) => (
                                                                <option key={l.id} value={l.id}>
                                                                    {l.name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    :
                                                    p.hasOwnProperty("status")
                                                    && assetStatuses.find((l) => l.id === p.status.toString())?.name
                                                    }

                                            </td>
                                            <td data-label="Házon belül">
                                                <Form.Check type="checkbox" id="maint_inhouse" onChange={(event) => handleChangeField(event, p.id)} checked={assets.find((x) => x.id === p.id)?.maint_inhouse} />
                                                <Calendar
                                                    color="#0d6efd"
                                                    style={{cursor: "pointer"}}
                                                    onClick={(event) => handleShowCalModal(event, p.id, 0 )} />
                                            </td>
                                            <td data-label="Külsős"><Form.Check type="checkbox" id="maint_external" onChange={(event) => handleChangeField(event, p.id)} checked={assets.find((x) => x.id === p.id)?.maint_external} />
                                            <Calendar
                                                    color="#0d6efd"
                                                    style={{cursor: "pointer"}}
                                                    onClick={(event) => handleShowCalModal(event, p.id, 1 )} />
                                                    
                                                    </td>
                                            <td data-label="Megjegyzések">
                                                  {/*   {p.hasOwnProperty("notes") && p.notes.length > 9 ? p.notes.substring(0,10)+"..." : p.notes} */}
                                                    <StickyFill
                                                    color="#0d6efd"
                                                    style={{cursor: "pointer"}}
                                                        onClick={(event) => handleShowNotesModal(event, p.id)} />
                                                </td>
                                            {(userDetails?.hasOwnProperty("level") && userDetails.level === 0) &&
                                                <td data-label="Törlés">
                                                    <XSquareFill
                                                        color="#0d6efd"
                                                        style={{cursor: "pointer"}}
                                                        onClick={(event) => handleDeleteAsset(event, p.id)} />
                                                </td>}
                                        </tr>
                                    );
                                }
                            )}
                    </tbody>
                </table>
                   
            </Container>

          {/*   Notes modal */}

                    <Modal show={showNotes} onHide={handleCloseNotes}>
                        <Modal.Header closeButton>
                        <Modal.Title>Megjegyzések</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control id="notes" value={assets?.find((x) => x.id === currentAsset)?.notes} as="textarea" rows={3} onChange={(event) => handleChangeNotes(event, currentAsset)} />
                    
                   {/*  {assets?.find((x) => x.id === currentAsset)?.notes} */}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" id="notes" onClick={(event) => handleChangeField(event, currentAsset)}>
                            Mentés
                        </Button>
                        <Button variant="primary" onClick={handleCloseNotes}>
                            Bezárás
                        </Button>
                        </Modal.Footer>
                    </Modal>

           {/*  Dátum modal */}

           <Modal show={showDates} onHide={handleCloseDates}>
                        <Modal.Header closeButton>
                        <Modal.Title>{dateType ? "Külsős" : "Belsős"} karbantartás dátumai</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {/*  <Form.Control id="dates" value={assets?.find((x) => x.id === currentAsset)?.notes} as="textarea" rows={3} onChange={(event) => handleChangeNotes(event, currentAsset)} /> */}
                    
                   {/*  {assets?.find((x) => x.id === currentAsset)?.notes} */}
                   <Row>
                    <Col sm={4}>
                        Kezdő dátum
                    </Col>
                    <Col sm={6}>
                    <Form.Control
                                          id="startDate"
                                          onChange={(event) => handleChangeDates(event, currentAsset, dateType)}
                                          type="date"
                                          value={timestampToDate(planned?.find((p) => (p.asset === currentAsset && p.type === dateType))?.dtstart)}></Form.Control>
                    </Col>
                   </Row>
                   <Row>
                    <Col sm={4}>
                        Gyakoriság
                    </Col>
                    <Col sm={6}>
                    <Form.Select
                            aria-label="Gyakoriság"
                            id="freq"
                            value={planned?.find((p) => (p.asset === currentAsset && p.type === dateType))?.hasOwnProperty("freq") ? planned?.find((p) => (p.asset === currentAsset && p.type === dateType))?.freq : "daily"}
                            onChange={(event) => handleChangeDates(event, currentAsset, dateType)}>
                            <option value="daily">Naponta</option>
                            <option value="weekly">Hetente</option>
                            <option value="monthly">Havonta</option>
                            <option value="yearly">Évente</option>
                        </Form.Select>
                    </Col>
                   </Row>
                   </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" id="notes" onClick={(event) => handleChangePlanned(event, currentAsset, dateType)}>
                            Mentés
                        </Button>
                        <Button variant="primary" onClick={handleCloseDates}>
                            Bezárás
                        </Button>
                        </Modal.Footer>
            </Modal>
            
            <Modal show={showAsset} onHide={handleCloseAsset} className={"modal-xl"}>
                        <Modal.Header closeButton>
                        <Modal.Title>Eszköz hozzáadása</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditAsset
                        users={users}
                        userDetails={userDetails}
                        showToast={showToast}
                        setShowToast={setShowToast}
                        handleCloseAsset={handleCloseAsset}
                        locations={locations}
                    />
                 </Modal.Body>
                        <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseAsset}>
                            Bezárás
                        </Button>
                        </Modal.Footer>
               </Modal>    

                <HVToast
                        txt="Eszköz mentve"
                        showToast={showToast}
                        setShowToast={setShowToast}
                    />


        </div>
    );
}

export default Assets;
