import "./styles.css";
import React from "react";
import { Link, UNSAFE_DataRouterStateContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { onAuthStateChanged, signOut, getAuth, deleteUser } from "firebase/auth";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Gallery from "./Gallery";
import Tickets from "./Tickets";
import EditTicket from "./EditTicket";
import Users from "./Users";
import EditUser from "./EditUser";
import MainCalendar from "./MainCalendar";
import Locations from "./Locations";
import Assets from "./Assets";
import EditAsset from "./EditAsset";
import Externals from "./Externals";
import EditExternal from "./EditExternal";
import Planned from "./Planned";
import EditPlanned from "./EditPlanned";
import { collection, onSnapshot, query} from "firebase/firestore";
import { db } from "./firebase";
import packageInfo from '../package.json';

function App() {
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (userFromDb) => {

      if (userFromDb) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(userFromDb);
        // betöltjük a részleteket a firestoreból (név, email, stb).
        const q = query(collection(db, "users"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const newData = [];
          querySnapshot.forEach((doc) => {
            newData.push({ id: doc.id, ...doc.data() });
          });
          setUsers(newData);
          setUserDetails(newData.find((x) => x.uId === userFromDb.uid));
        });
        return unsubscribe;
      } else {
        // User is signed out
        // ...
      }
    });
  }, []);

  
useEffect(() => {
                   document.title = user ? "Hotel Visegrád CMMS" : "..."
              }, [user])

  return (
    <div className="App">

      {user ? (
        <>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Tickets users={users} userDetails={userDetails} />
                </>
              }
            />
             <Route
              path="/tickets"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Tickets users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/ticket"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditTicket users={users} userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/login"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Login userDetails={userDetails} />
                </>
              }
            />
            <Route
              path="/gallery"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Gallery users={users} userDetails={userDetails} />
                </>
              }
            />
             <Route
              path="/calendar"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <MainCalendar users={users} userDetails={userDetails} />
                </>
              }
            />
            {userDetails?.hasOwnProperty("level") && userDetails.level <= 3}
            <>
            <Route
              path="/assets"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Assets users={users} userDetails={userDetails} />
                </>
              }
              />
              <Route
              path="/asset"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditAsset users={users} userDetails={userDetails} />
                </>
              }
              />
              <Route
              path="/externals"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Externals users={users} userDetails={userDetails} />
                </>
              }
              />
               <Route
              path="/external"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditExternal users={users} userDetails={userDetails} />
                </>
              }
              />
              <Route
              path="/planned"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Planned users={users} userDetails={userDetails} />
                </>
              }
              />
              <Route
              path="/editplanned"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditPlanned users={users} userDetails={userDetails} />
                </>
              }
            />
            </>
            {(userDetails?.hasOwnProperty("level") && userDetails.level === 0) &&
              <>
              <Route
              path="/users"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Users users={users} userDetails={userDetails} />
                </>
              }
              />
               <Route
              path="/user"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <EditUser users={users} userDetails={userDetails} />
                </>
              }
              />
              <Route
              path="/departments"
              element={
                <>
                  <AdminNav user={user} setUser={setUser} userDetails={userDetails} />
                  <Locations users={users} userDetails={userDetails} />
                </>
              }
              />
              
              </>
              
            }
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/*" element={<Login />} />
        </Routes>
      )}
    </div>
  );
}

function AdminNav(props) {
  const user = props.user;
  const setUser = props.setUser;
  const userDetails = props.userDetails;
  const navigate = useNavigate();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setUser();
        navigate("/login");
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          HOTEL Visegrád CMMS {packageInfo.version}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="container-fluid">
            <Nav.Link as={Link} to="/tickets">
              Feladatok
            </Nav.Link>
            <Nav.Link as={Link} to="/calendar">
              Naptár
            </Nav.Link>
            <Nav.Link as={Link} to="/planned">
              Tervezett
            </Nav.Link>
            <Nav.Link as={Link} to="/assets">
              Eszközök
            </Nav.Link>
            <Nav.Link as={Link} to="/externals">
              Szolgáltatók
            </Nav.Link>
            {userDetails?.hasOwnProperty("level") &&
              userDetails.level === 0 && (
                <>
                  <NavDropdown title="Admin" id="admin">
                    <NavDropdown.Item as={Link} to="/users">
                      Felhasználók
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/locations">
                      Helyszínek
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              )}
            {/*  <NavDropdown title="Nyelv" id="lang">
              {langs.map((l) => (
                <NavDropdown.Item key={l.id}>{l.name}</NavDropdown.Item>
              ))}
            </NavDropdown> */}
            <Nav.Item className="ms-auto">
              <Nav.Link onClick={handleLogout}>
                {userDetails?.name ? userDetails?.name : userDetails?.email}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default App;