import axios from 'axios';

export function sendTicketEmail(type, emailAddress, ticketTitle, deadline, location) {
  
  const deadlineDate = new Date(deadline * 1000);

  var emailSubject = "";

  switch (type) {
    case "newTicket":
      emailSubject = "Új feladat: ";
      break;
    case "assignedTicket":
      emailSubject = "Feladat kiosztva neked: ";
      break;
    default:
      emailSubject = "";
  }

  const emailData = {
      to: emailAddress,
      subject: emailSubject + ticketTitle,
      message: "Helyszín: " + location + "\nHatáridő: " + deadlineDate.toLocaleDateString([], { hour: "2-digit", minute: "2-digit" }),
    }

    axios({
      method: "POST",
      url:"https://k.ren.hu/mail.php",
      data:  emailData
    }).then((response)=>{
      if (response.data.status === 'success') {
        console.log("Email sent.");
      } else if (response.data.status === 'fail') {
          console.log("response", response.data)
        console.log("Message failed to send.")
      }
    })

  
};