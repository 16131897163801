import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button
} from "react-bootstrap";
import { db } from "./firebase";
import {
  onSnapshot,
  updateDoc,
  collection,
  addDoc,
  doc,
    query,
    deleteDoc
} from "firebase/firestore";

function EditExternalTags(props) {
  const setShowToast = props.setShowToast;
  const [editable, setEditable] = useState();

  const [tags, setTags] = useState();
  const [newTag, setNewTag] = useState({tag: ""});

function tagsData() {
  const q = query(collection(db, "externalTags"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const newData = [];
    querySnapshot.forEach((doc) => {
      newData.push({
        id: doc.id,
        ...doc.data(),
      });
    });
      setTags([...newData]);
  });
  return unsubscribe;
}
useEffect(() => tagsData(), []);

    function handleUpdateTag(event, tId) {
    event.preventDefault();

        if (tId !== "new") {
            const docRef = doc(db, "externalTags", tId);
            updateDoc(docRef,
                { tag: tags.find((t) => t.id === tId)?.tag
        })
                .then((docRef) => {
                    setEditable();
          console.log("Document field updated");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const dbRef = collection(db, "externalTags");

      addDoc(dbRef, newTag)
          .then((docRef) => {
            setNewTag();
          console.log(docRef.id); //p4eZcO5QV43IYnigxALJ
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleChangeTag(event, tagId) {
    var tempArray = [...tags];
    var tempTag = tempArray.find((c) => c.id === tagId);
      tempTag.tag = event.target.value;
    setTags([...tempArray]);
  }

  function handleDeleteTag(event, tId) {
    event.preventDefault();
    if (window.confirm("Biztosan törlöd ezt az adatot?")) {
      /* var tempArray = [...tags];
      tempArray = tempArray.filter((c) => c.id !== contactId);
      setTags([...tempArray]); */
        const docRef = doc(db, "externalTags", tId);
        deleteDoc(docRef, tId)
          .then((docRef) => {
            console.log("Document field updated");
          })
          .catch((error) => {
            console.log(error);
          });
        
    }
  }

  return (
    tags && (
      <div className="EditExternalTags">
        <Container>
          <Form.Group as={Row} className="pb-4 pt-4" controlId="new">
            <Col sm={3} xs={12}>
              <Col>
                <Form.Control
                  placeholder="Címke"
                  value={newTag?.tag}
                  onChange={(event) =>
                    setNewTag({ ...newTag, tag: event.target.value })
                  }
                />
              </Col>
            </Col>
            <Col sm={3} xs={12} className="d-flex align-items-end">
              <Col>
                <Button onClick={(event) => handleUpdateTag(event, "new")}>
                  Címke hozzáadása
                </Button>
              </Col>
            </Col>
          </Form.Group>

          <Row className="pt-4 pb-4">
            <Col sm={12} xs={12}>
              Címkék
            </Col>
          </Row>

          {/*  MEGLÉVŐ KAPCSOLATOK SZERKESZTÉSE */}

          {tags?.map((tag) => (
            <Form.Group as={Row} className="pb-2">
              <Col sm={3} xs={12}>
                <Col>
                  <Form.Control
                    id={tag.id}
                    disabled={editable !== tag.id}
                    value={tag.tag}
                    onChange={(event) => handleChangeTag(event, tag.id)}
                  />
                </Col>
              </Col>
              <Col sm={2} xs={12} className="d-flex align-items-end">
                <Col>
                  {editable !== tag.id ? (
                    <Button onClick={(event) => setEditable(tag.id)}>
                      Szerkesztés
                    </Button>
                  ) : (
                    <Button onClick={(event) => handleUpdateTag(event, tag.id)}>
                      Mentés
                    </Button>
                  )}
                </Col>
              </Col>
              <Col sm={2} xs={12} className="d-flex align-items-end">
                <Col>
                  <Button onClick={(event) => handleDeleteTag(event, tag.id)}>
                    Törlés
                  </Button>
                </Col>
              </Col>
            </Form.Group>
          ))}
        </Container>
      </div>
    )
  );
}

export default EditExternalTags;
